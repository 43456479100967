import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstant } from "src/app/app.constant";
import { HttpHandlerService } from "./http-handler.service";

@Injectable({
  providedIn: "root",
})
export class KPIReportingService {
  endpoint: string;
  serviceURL: any;
  constructor(private httpHandler: HttpHandlerService) {
    this.endpoint = AppConstant.API_END_POINT;
    this.serviceURL = AppConstant.API_CONFIG.API_URL.BASE.KPITREPORTING;
  }
  getSyntheticRegions(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.SYNTHETIC_REGIONS;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }
  
  getSyntheticReport(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.SYNTHETICS;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }

  getAllssl(data, query?): Observable<any>{
    let url = this.endpoint + this.serviceURL.SSL;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }

  getCMDBQueryReport(data, query?): Observable<any> {
    let url = this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS.CMDBCHART;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }
  all(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.FINDALL;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }
  alldetails(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.DETAILS.FINDALL;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }

  // customer kpi

  customerkpiall(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.CUSTOMER.FINDALL;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }

  getassets(data, query?): Observable<any> {
    let url =
      this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.INSTANCE.CHART;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }

  getcmdb(data, query?): Observable<any> {
    let url =
      this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS.CHART;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }

  getResourceTypes(tenantid: number) {
    return this.httpHandler.POST(
      this.endpoint +
      AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS.RESOURCETYPE,
      {
        tenantid,
      }
    );
  }
  getCustomReports(data, query?) {
    let url = this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS.CUSTOMREPORT;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }
  getCustomReportDetails(data, query?) {
    let url = this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS.CUSTOMREPORTDETAILS;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }
  getCustomReportTaskList(data, query?) {
    let url = this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS.CUSTOMREPORT_TASKS;
    if (query) url += query;
    return this.httpHandler.POST(url, data);
  }
}
