import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { AppConstant } from "src/app/app.constant";
import { AssetRecordService } from "src/app/services/assetrecords.service";
@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.css"],
})
export class FiltersComponent implements OnInit {
  @Input() tenantid;
  @Input() reporttype;
  @Input() reportdetails: any = {};
  @Input() selectedValues = [];
  @Output() applyFilters: EventEmitter<any> = new EventEmitter();
  label: any = {};
  filterableValues = [];
  selectedFilters = [];
  constructor(private assetRecordService: AssetRecordService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reporttype && changes.reporttype.currentValue) {
      this.reporttype = changes.reporttype.currentValue;
    }
    if (changes.reportdetails && changes.reportdetails.currentValue) {
      this.selectedFilters = [];
      this.reportdetails = changes.reportdetails.currentValue;
      this.formLabel();
    }
  }

  ngOnInit() { }

  formLabel() {
    if (!_.isEmpty(this.reportdetails)) {
      let settings = JSON.parse(this.reportdetails.settings);
      if (this.reportdetails.charttype == "rangeBar") {
        this.label = settings ? settings.yaxis.fieldname : "";
        this.getValues(settings.yaxis.fieldkey);
      } else {
        this.label = settings ? settings.xaxis.fieldname : "";
        this.getValues(settings.xaxis.fieldkey);
      }
    }
  }

  getValues(fieldkey) {
    let condition = {
      tenantid: this.tenantid,
      fieldkey: fieldkey,
      status: AppConstant.STATUS.ACTIVE,
    };
    if (this.reportdetails.charttype == "rangeBar") {
      condition["groupby"] = true;
    }
    this.assetRecordService.getAllDetail(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          let obj = {
            title: el.fieldvalue,
            value: el.fieldvalue,
          };
          if (el.count) {
            obj.title = obj.title + " (Total tasks - " + el.count + " )";
          }
          return obj;
        });
        this.filterableValues = _.uniqBy(this.filterableValues, "value");

        if (
          this.reportdetails.selectedValues &&
          this.reportdetails.selectedValues.length > 0
        ) {
          this.selectedFilters = this.reportdetails.selectedValues;
        } else {
          this.selectedFilters = [];
        }
      } else {
        this.filterableValues = [];
      }
    });
  }

  apply() {
    this.applyFilters.next({
      reporttype: this.reporttype,
      filters: this.selectedFilters,
    });
  }
}
