import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstant } from "../app.constant";
import * as moment from "moment";
import { HttpHandlerService } from "../services/http-handler.service";

export interface ITag {
  tagid: number;
  tenantid: number;
  resourcetype: null;
  tagname: string;
  tagtype: string;
  regex: null;
  description: null;
  lookupvalues: null;
  required: boolean;
  status: string;
  createdby: string;
  createddt: Date;
  lastupdatedby: string;
  lastupdateddt: Date;
}

@Injectable({
  providedIn: "root",
})
export class TagService {
  endpoint: string;
  serviceURL: any;
  constructor(private httpHandler: HttpHandlerService) {
    this.endpoint = AppConstant.API_END_POINT;
    this.serviceURL = AppConstant.API_CONFIG.API_URL.BASE.TAGS;
  }

  all(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.FINDALL;
    if (query) url = url + '?' + query;
    return this.httpHandler.POST(url, data);
  }


}
