import { Injectable } from "@angular/core";
import { Http,Headers } from "@angular/http";
// import 'rxjs/Rx';
import { Observable } from "rxjs";

@Injectable()
export class HttpHandlerService {
  constructor(
    private http: Http,
  ) { }

  GET(url: string,options?:any): Observable<any> {
    if(options){
      // let reqHeader = new Headers();
      // headerList.forEach((data:any)=>{
      //   let key = Object.keys(data);
      //   // headers .set(key[0], data[key[0]]);
      //   reqHeader.append(key[0],data[key[0]])
      // });
      return this.http.get(url, { headers: {...options} });
    }
    return this.http.get(url, {
    });
  }

  POST(url: string, data: any, options?: any): Observable<any> {
    if (options) {
      return this.http.post(url, data, { headers: { ...options } });
    } else {
      return this.http.post(url, data, {});
    }
  }

  DELETE(url: string, options?: any): Observable<any> {

    if (options) {
      return this.http.delete(url, { headers: { ...options } });
    } else {
      return this.http.delete(url, {});
    }
  }
}
