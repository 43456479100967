import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { TenantsService } from "./services/tenants.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private tenantsService: TenantsService) {}
  public checkAuthStatus(customerid: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (customerid) {
        this.tenantsService.customerbyId(customerid).subscribe((result) => {
          let response = JSON.parse(result._body);
          if (response.status) {
            let config: any = response.data.dashboardconfig;
            let authyn: any = _.find(config, {
              key: "AUTHREQUIRED",
            });
            return resolve(authyn ? authyn.value : true);
          } else {
            return reject(false);
          }
        });
      } else {
        return reject(false);
      }
    });
  }
  public checkAuth(email: string, customerid: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (email && customerid) {
        this.tenantsService
          .checkPermission({ email: email, customerid: customerid })
          .subscribe((result) => {
            let response = JSON.parse(result._body);
            return resolve(response.status);
          });
      } else {
        return reject(false);
      }
    });
  }
  public getSession(): Promise<boolean> {
    const session = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      if (session) {
        return resolve(true);
      } else {
        return reject(false);
      }
    });
  }
}
