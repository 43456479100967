import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AssetRecordService } from "../../../services/assetrecords.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexLegend,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions,
  ApexNonAxisChartSeries
} from "ng-apexcharts";
import * as moment from "moment";
import * as _ from "lodash";
import { CommonService } from "src/app/services/common.service";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  colors: any[];
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: any; // ApexStroke;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  tooltip: ApexTooltip;
};
export type HeapMemoryChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: ApexLegend;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};
@Component({
  selector: "app-cloudmatiq-sqlexceptionChart",
  styles: [],
  templateUrl: "./sqlexception.component.html",
})
export class AppDSqlExeceptionComponent implements OnInit {
  @Input() chartWidgetDetails: any;
  @Input() instanceconfig: any;
  @Input() customerObj: any;
  @Input() pageSize = 5;
  @Input() InstanceName = "";
  @Input() appdynamicAccessToken = "";
  @Input() appDynamicConfgs;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public HeapMemoryChartOptions: Partial<HeapMemoryChartOptions>;
  applicationID;
  nodeID;
  appDapplicationDetails;
  hidden: boolean = true;
  isSqlExeceptionChart = false;
  appDynamicConnected = false;
  noDataFound = true;
  timeRange = 'last_1_hour.BEFORE_NOW.-1.-1.60';
  baseline = -1;
  maxDataPoint = 1440;
  timerange = 'last_1_hour.BEFORE_NOW.-1.-1.60';
  timeranges = [];
  dataloading = false;
  heapmemoryChartconfig = [];
  heapmemory_timerange = '';
  chartWidth=1024;
  constructor(
    private assetRecordService: AssetRecordService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
  ) {
    this.chartOptions = {
      series: [
      ],
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: "zoom"
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "50%",
      //   },
      // },

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      // labels: [
      //   "01/01/2003",
      //   "02/01/2003",
      //   "03/01/2003",
      //   "04/01/2003",
      //   "05/01/2003",
      //   "06/01/2003",
      //   "07/01/2003",
      //   "08/01/2003",
      //   "09/01/2003",
      //   "10/01/2003",
      //   "11/01/2003",
      // ],
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: {
        title: {
          text: "Calls",
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
      },
    };
    this.HeapMemoryChartOptions = {
      series: [],
      chart: {
        type: "radialBar",
        offsetY: -40
      },
      legend: {
        show: false,
        position: "top",
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 20, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: true
            },
            value: {
              offsetY: -40,
              fontSize: "22px"
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: ["Average Results"]
    }
  }
  ngOnInit() {
    this.getLookupData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartWidgetDetails"]) {
      if (changes["appDynamicConfgs"]) {

      }
      this.searchNode();
    }
  }
  getLookupData() {
    this.dataloading = true;
    const condition = {
      lookupkey: 'APPDYNAMIC_TIMERANGE',
      status: 'Active'
    }
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.timeranges = _.orderBy(response.data, ['lookupid'], ['asc']);
      }
      this.dataloading = false;
    });
  }
  searchNode() {
    if (this.appDynamicConfgs.url) {
      if (this.chartWidgetDetails.title == "SQL server – General load information") {
        // if (this.instanceconfig.displayname == "NES6CH-APP001-prd") {
          this.switchChartAPI();
        // }
      }
      else {
        let url = this.appDynamicConfgs.url + `${this.appDynamicConfgs.searchnode}`;//this.sanitizer.bypassSecurityTrustResourceUrl(this.chartWidgetDetails.url);
        const headers = {
          Authorization: `Bearer ${this.appdynamicAccessToken}`,
        };
        let redata = {
          "searchText": this.instanceconfig.displayname + "-app",
          "pageSize": 50,
          "offset": 0,
          "entityTypes": []
        }
        let req = {
          method: "post",
          url: url,
          headers: headers,
          data: redata,
        };
        this.assetRecordService
          .getAppDynamicChartData(req)
          .subscribe((result: any) => {
            this.dataloading = false;
            let response = JSON.parse(result._body);
            if (response.status) {
              if (response.data) {
                let searchResults: any[] = response.data.searchResults
                let instance: any = _.find(searchResults, (d) => {
                  return this.instanceconfig.displayname.toLowerCase().indexOf(d.displayText.toLowerCase());
                });
                if (instance) {
                  if (instance.context) {
                    this.applicationID = instance.context.PARENT_APPLICATION_ID;
                  }
                  this.nodeID = instance.entityId;
                  this.appDapplicationDetails = instance;
                  this.switchChartAPI();
                }
              }
            }
          });
      }

    }
  }
  switchChartAPI() {
    // if (this.instanceconfig.displayname == "NES6CH-APP001-prd") {
      if (this.appdynamicAccessToken != "" && this.appdynamicAccessToken != null) {

        if (this.applicationID && this.chartWidgetDetails.title == "SQL Exception") {
          this.getSqlExceptionData();
        }
        if (this.applicationID && this.chartWidgetDetails.title == "Java Heap Memory Utilization") {
          this.getJVMHeapData();
        }
      }
      if (this.chartWidgetDetails.title == "SQL server – General load information") {
        this.getGenralLoadSqlData();
      }
    // }
  }
  getSqlExceptionData() {
    this.isSqlExeceptionChart = true;
    this.dataloading = true;
    if (this.chartWidgetDetails.url) {
      let url = this.chartWidgetDetails.url + this.chartWidgetDetails.application_id + `?time-range=${this.timerange}&baseline=${this.baseline}&max-data-points=${this.maxDataPoint}`;//this.sanitizer.bypassSecurityTrustResourceUrl(this.chartWidgetDetails.url);
      const headers = {
        Authorization: `Bearer ${this.appdynamicAccessToken}`,
      };
      let reqData: any ={};
      let fromTimestamp = new Date().getTime();
      let toTimestamp = new Date(new Date().getTime() - (1000 * 60 * 60)).getTime();
      if(this.chartWidgetDetails.fromTimestamp && this.chartWidgetDetails.toTimestamp ){
        fromTimestamp=this.chartWidgetDetails.fromTimestamp;
        toTimestamp=this.chartWidgetDetails.toTimestamp;
      }
      url = url + `&time-range=Custom_Time_Range.BETWEEN_TIMES.${toTimestamp}.${fromTimestamp}.0`;
      // reqData.timeRange = `Custom_Time_Range.BETWEEN_TIMES.${toTimestamp}.${fromTimestamp}.0`;
      let req = {
        method: "get",
        url: url,
        headers: headers,
        data: reqData,
      };
      this.assetRecordService
        .getAppDynamicChartData(req)
        .subscribe((result: any) => {
          this.dataloading = false;
          let response = JSON.parse(result._body);
          if (response.status) {
            this.prepareSqlExecChartData(response.data);
          }
        });
      // this.commonService.commonGetCall(url,query,hdr).subscribe((res) => {
      //   console.log(res);
      // });
    }
  }
  getJVMHeapData() {
    this.isSqlExeceptionChart = true;
    this.dataloading = true;
    if (this.chartWidgetDetails.url) {
      let url = this.chartWidgetDetails.url;//this.sanitizer.bypassSecurityTrustResourceUrl(this.chartWidgetDetails.url);
      const headers = {
        Authorization: `Bearer ${this.appdynamicAccessToken}`,
      };
      let reqData: any = this.chartWidgetDetails.params;
      reqData.nodeId = this.nodeID;
      reqData.applicationId = this.applicationID;
      let fromTimestamp = new Date().getTime();
      let toTimestamp = new Date(new Date().getTime() - (1000 * 60 * 60)).getTime();
      if(this.chartWidgetDetails.fromTimestamp && this.chartWidgetDetails.toTimestamp ){
        fromTimestamp=this.chartWidgetDetails.fromTimestamp;
        toTimestamp=this.chartWidgetDetails.toTimestamp;
      }
      reqData.timeRange = `Custom_Time_Range.BETWEEN_TIMES.${toTimestamp}.${fromTimestamp}.0`;
      let req = {
        method: "post",
        url: url,
        headers: headers,
        data: reqData,
      };
      this.assetRecordService
        .getAppDynamicChartData(req)
        .subscribe((result: any) => {
          this.dataloading = false;
          let response = JSON.parse(result._body);
          if (response.status) {
            this.prepareJVMHeapChartData(response.data);
          }
        });
    }

  }

  calculatePercentage(array) {
    if (array.length === 0) {
      return 0; // Return 0 if the array is empty
    }

    const sum = array.reduce((total, value) => total + value, 0);
    const average = sum / array.length;
    const percentage = (average / 100) * 100;

    return percentage;
  }
  prepareJVMHeapChartData(responseData) {
    let averageutilization: any = 0;
    let currentutilization: any = 0;

    let currentusage: any = _.find(responseData, { metricName: "JVM|Memory:Heap|Used %" });
    if (currentusage) {
      currentutilization = currentusage.dataPoints[currentusage.dataPoints.length - 1].metricValue.value;
      console.log('overall', currentutilization);
      averageutilization = Math.round(this.calculatePercentage(
        _.map(currentusage.dataPoints,
           function (e) {
             return e.metricValue? e.metricValue.value : 0;
             }))
        );
      console.log('Avg', averageutilization)
      this.HeapMemoryChartOptions.series = [averageutilization];
      this.HeapMemoryChartOptions.labels = ['Average Utilization']
      this.heapmemoryChartconfig.push({ ...this.HeapMemoryChartOptions });
      this.HeapMemoryChartOptions.series = [];
      this.HeapMemoryChartOptions.labels = ['Current Utilization']
      this.HeapMemoryChartOptions.series = [currentutilization];
      this.heapmemoryChartconfig.push({ ...this.HeapMemoryChartOptions });
      this.noDataFound = false;
    }

  }
  prepareSqlExecChartData(responseData) {
    console.log("prepareSqlExecChartData");
    let series = [];

    if (responseData.histogramDataMap) {
      let labels = [];
      let series_keys: any[] = Object.keys(responseData.histogramDataMap);
      series_keys.forEach((skey) => {
        if (skey == 'ERROR') {
          let type = "column";
          // switch (skey) {
          //   case "ERROR":
          //     type = "column";
          //     break;
          //   case "VERY_SLOW":
          //     type = "line";
          //     break;
          //   case "SLOW":
          //     type = "line";
          //     break;
          //   case "STALL":
          //     type = "line";
          //     break;
          //   case "NORMAL":
          //     type = "line";
          //     break;
          //   default:
          //     break;
          // }
          let dataTimeslicesData = [];
          if (responseData.histogramDataMap[skey]["dataTimeslices"]) {
            let dataTimeslices: any[] = responseData.histogramDataMap[skey]["dataTimeslices"];
            dataTimeslices.forEach((s) => {
              dataTimeslicesData.push([s.startTime, s.metricValue.value]);
              let startTime = s.startTime
              // labels.push(moment(startTime).format('hh:mm a'));
              // labels.push(startTime);
            });
          }
          series.push({
            name: skey,
            type: type,
            data: dataTimeslicesData
          });
        }


      });

    }

    if (responseData.averageResponseTimeData) {
      let averageResponseTimeData = [];
      let dataTimeslices: any[] = responseData.averageResponseTimeData.dataTimeslices;
      dataTimeslices.forEach((s) => {
        averageResponseTimeData.push([s.startTime, s.metricValue.value]);
      });
      series.push({
        name: 'Average Response Time',
        type: 'line',
        data: averageResponseTimeData
      });
    }
    // labels = _.uniqWith(labels, _.isEqual)
    // this.chartOptions.labels = labels;
    this.chartOptions.colors = ['#FF0000', '#0000FF']
    this.chartOptions.series = series;
    // setTimeout(() => {
    //   _.each(this.chartOptions.series,(s)=>{
    //     if(s.name != "ERROR" && s.name != "SLOW"){
    //       this.chart.toggleSeries(s.name);
    //     }
    //   })

    // }, 2000);
    this.noDataFound = false;
  }

  getGenralLoadSqlData() {

    this.isSqlExeceptionChart = true;
    this.dataloading = true;
    if (this.chartWidgetDetails.url) {
      let url = this.chartWidgetDetails.url;//this.sanitizer.bypassSecurityTrustResourceUrl(this.chartWidgetDetails.url);
      const headers = {
        Authorization: `Bearer ${this.appdynamicAccessToken}`,
      };
      let reqData: any = this.chartWidgetDetails.params;
      let fromTimestamp = new Date().getTime();
      let toTimestamp = new Date(new Date().getTime() - (1000 * 60 * 60)).getTime();
      if(this.chartWidgetDetails.fromTimestamp && this.chartWidgetDetails.toTimestamp ){
        fromTimestamp=this.chartWidgetDetails.fromTimestamp;
        toTimestamp=this.chartWidgetDetails.toTimestamp;
      }
      // reqData.timeRange = `Custom_Time_Range.BETWEEN_TIMES.${toTimestamp}.${fromTimestamp}.0`;
      // reqData.nodeId = this.nodeID;
      // reqData.applicationId = this.applicationID;
      // reqData.timeRange = "Custom_Time_Range.BETWEEN_TIMES.1687149918950.1687146318950.0";
      if(reqData.timeRangeSpecifier){
        reqData.timeRangeSpecifier= {
          "type": "BETWEEN_TIMES",
          "durationInMinutes": 60,
          "endTime": toTimestamp,
          "startTime": fromTimestamp
        }
      }
      let req = {
        method: "post",
        url: url,
        headers: headers,
        data: reqData,
      };
      this.assetRecordService
        .getAppDynamicChartData(req)
        .subscribe((result: any) => {
          this.dataloading = false;
          let response = JSON.parse(result._body);
          if (response.status) {
            this.prepareSqlGenralLoadChartData(response.data);
          }
        });
    }

  }

  prepareSqlGenralLoadChartData(responseData: any) {
    console.log("prepareSqlGenralLoadChartData");
    let series = [];
    this.chartOptions.series = [];
    if (responseData.length > 0) {

      let labels = [];
      
      _.each(responseData,(d)=>{
        let dataTimeslicesData=[];
        _.each(d.dataTimeslices,(s)=>{
          if(s.metricValue){
            dataTimeslicesData.push([s.startTime, s.metricValue.value]);
          }
          else{
            dataTimeslicesData.push([s.startTime, 0]);
          }
        })
        let type = "line";
        switch (d.metricName) {
          case "DB|KPI|Time Spent in Executions (s)":
            type = "line";
            break;
          case "Hardware Resources|CPU|%Busy":
            type = "line";
            break;
          default:
            break;
        }
        //     bre
        series.push({
          name: d.metricName,
          type: type,
          data: dataTimeslicesData
        });
      });
    this.chartOptions.colors = ['#FF0000', '#0000FF'];
    this.chartOptions.series = series;
    this.chartOptions.yaxis= {
      title: {
        text: "Spent",
      },
      min: 0,
    };
    this.noDataFound = false;
    }

  }
}
