import { Directive, Output } from "@angular/core";
import { EventEmitter } from '@angular/core';


@Directive({ selector: '[invoke]'})
export class InvokeDirective {
  @Output() invoke = new EventEmitter();
  ngOnInit() {
    this.invoke.emit(null);
  }
}