import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";

import { AppConstant } from "src/app/app.constant";
import * as _ from "lodash";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AssetRecordService } from "../../services/assetrecords.service";
import { Buffer } from "buffer";
import { AssetAttributesConstant } from "../../attributes.contant";
import * as Papa from "papaparse";
@Component({
  selector: "app-asset-records",
  templateUrl:
    "./assetrecords.component.html",
  styles: [
    `
      #assetdetail td,
      #assetdetail th {
        border: 1px solid #dddddd30;
        padding: 8px;
      }

      #assetdetail tr:nth-child(even) {
        background-color: #dddddd1c;
      }

      #assetdetail th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #1c2e3cb3;
        color: white;
      }
    `,
  ],
})
export class AssetRecordsComponent implements OnInit {
  @Input() assetQryDetails: any;
  @Input() customerObj: any;
  @Input() pageSize = 5;
  @Input() InstanceName = "";
  resource = {} as any;
  selectedcolumns = {};
  selectedResource = [];
  selectedFields = null as null | any[];
  pageIndex = 1;
  filteredColumns = [];
  selectedResourceName;
  assets = [] as Record<string, any>[];
  gettingAssets = false;
  assetsCount = 0;
  filterMenuVisible = false;
  filterFieldKey = null as null | string;
  filterFieldName = null as null | string;
  filterValues = {};
  filterValuesClone = {}; // Clone to validate the state between filter drawer open and close
  filterSearchModel = "";
  filterableValues = [] as {
    fieldvalue: string;
    fieldkey: string;
    resourceid: string;
  }[];
  gettingFilterableValues = false;
  currentSortColumn = null;
  currentSortColumnOrder = null;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private assetRecordService: AssetRecordService
  ) {

  }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["assetQryDetails"]) {
      if (this.assetQryDetails.crn) {
        this.selectedResourceName = this.assetQryDetails.crn;
        this.getResourceDetail(this.assetQryDetails.crn);
      }
    }
  }
  openFilterFor(hdr) {
    this.filterFieldKey = hdr.fieldkey;
    this.filterFieldName = hdr.fieldname;

    this.filterValuesClone = _.cloneDeep(this.filterValues);
    if (!this.filterValuesClone[hdr.fieldname]) {
      this.filterValuesClone[hdr.fieldname] = {};
    }

    this.getFieldValues();
    this.filterSearchModel = "";
    this.filterMenuVisible = true;
  }
  getFieldValues() {
    this.filterableValues = [];
    if(this.filterFieldKey != null && this.filterFieldKey != undefined) {
      let f = {
        tenantid: this.customerObj[
          "tenantid"
        ],
        fieldkey: this.filterFieldKey,
      };
      if (this.filterSearchModel.length > 0) {
        f["search"] = this.filterSearchModel;
      }
      this.gettingFilterableValues = true;
      this.assetRecordService.getResourceFieldValues(f).subscribe(
        (d) => {
          let response: {
            fieldvalue: string;
            fieldkey: string;
            resourceid: string;
          }[] = JSON.parse(d._body);
          this.filterableValues = _.orderBy(response, ["fieldvalue"], ["asc"]);
          this.filterableValues=[...this.filterableValues];
          this.gettingFilterableValues = false;
        },
        (err) => {
          this.gettingFilterableValues = false;
        }
      );
    }
  }
  filterDrawerClose(revert: boolean) {
    if (revert == true) {
      this.filterMenuVisible = false;
      this.filterableValues = [];
      this.filterValuesClone = {};
    } else {
      const filtersArray = Object.keys(
        this.filterValuesClone[this.filterFieldName]
      ).filter((o) => {
        if (this.filterValuesClone[this.filterFieldName][o] == true) {
          return o;
        }
      });
      let f = {
        ...this.filterValuesClone,
      };

      if (filtersArray.length > 0) {
        f[this.filterFieldName] = {};
        filtersArray.forEach((o) => {
          f[this.filterFieldName][o] = true;
        });
      }
      // Remove empty filters
      Object.keys(f).forEach(
        (k) => (f[k] == null || Object.keys(f[k]).length <= 0) && delete f[k]
      );
      for (const key in f) {
        _.map(f[key], function (value, k) {
          if (value == false) {
            delete f[key][k];
          }
          if (_.isEmpty(f[key])) delete f[key];
        });
      }

      this.filterValues = f;
      this.filterMenuVisible = false;
      this.filterableValues = [];
      this.filterValuesClone = {};
      this.pageIndex = 1;

      this.getAssets();
    }
  }
  sortByColumn(hdr: any, sortOrder: string) {
    this.currentSortColumn = hdr.fieldname;
    this.currentSortColumnOrder = sortOrder;
    this.getAssets();
  }
  getResourceDetail(crn: string) {
    let r = this.resource[crn];
    if (!r) {
      this.selectedcolumns = {};
      this.filterValues = {};

      this.selectedResource = [];
      this.selectedFields = [];
      this.assets = [];
      this.assetRecordService
        .getResource(
          7,
          crn
        )
        .subscribe((d) => {
          let response: any[] = JSON.parse(d._body);
          this.selectedResource = response;
          this.filteredColumns = [];
          _.each(response, (itm: any, idx: number) => {
            itm.isSelected = itm.showbydefault ? true : false;
            if (itm.fieldname == "Customer Name" || itm.fieldname == "Instance Name") {
              itm.isSelected = true;
            }
            if (itm.fieldtype != "Reference Asset") {
              this.filteredColumns.push(itm);
            }
            if (itm.fieldtype == "Reference Asset") {
              let referenceasset = JSON.parse(itm.referenceasset);
              _.map(referenceasset.attribute, (dtl) => {
                let attribute: any = _.find(
                  AssetAttributesConstant.COLUMNS[referenceasset.assettype],
                  {
                    field: dtl,
                  }
                );
                this.filteredColumns.push({
                  referencekey: attribute.referencekey,
                  fieldname: attribute.header,
                  fieldtype: itm.fieldtype,
                  fieldkey: attribute.field,
                  linkid: attribute.linkid,
                  referenceasset: referenceasset,
                  assettype: referenceasset.assettype,
                });
              });
            }
            this.pageIndex = 1;
            this.pageSize = 5;
            if (idx + 1 == response.length) {
              this.resource[crn] = {
                filtered: this.filteredColumns,
                selected: response,
              };
              this.getAssets();
            }
          });
          this.filteredColumns = [... _.orderBy(this.filteredColumns, ["ordernumber", "desc"])];

        });
    }
  }
  getAssets(download: boolean = false) {
    this.gettingAssets = true;
    let selectedFields = [];
    let columns = [];
    // this.filterValues = {

    // };

    this.filteredColumns = [... _.orderBy(this.filteredColumns, ["ordernumber", "desc"])];
    _.map(this.filteredColumns, (itm: any) => {

      if (itm.isSelected) {
        if (itm.fieldname == "Customer Name") {
          this.filterValues["Customer Name"] = {};
          this.filterValues["Customer Name"][this.customerObj.customername] = true;
        }
        if (itm.fieldname == "Instance Name") {
          this.filterValues["Instance Name"] = {};
          this.filterValues["Instance Name"][this.InstanceName] = true;
        }
        if(itm.fieldtype == "Date" && itm.fieldname == "Date"){
          this.filterValues["Date"] = {};
          var currDate = moment(this.assetQryDetails.fromTimestamp).startOf('day');
          var lastDate = moment(this.assetQryDetails.toTimestamp).startOf('day');
          while(currDate.add(1, 'days').diff(lastDate) < 0) {
            currDate.clone().format('YYYY-MM-DD');
            this.filterValues["Date"][currDate.clone().format('YYYY-MM-DD')] = true;
          }
        }
        columns.push(
          _.pick(itm, [
            "fieldkey",
            "fieldname",
            "fieldtype",
            "assettype",
            "linkid",
            "isSelected",
            "referencekey",
          ])
        );
        selectedFields.push(
          _.pick(itm, [
            "fieldkey",
            "fieldname",
            "fieldtype",
            "assettype",
            "linkid",
            "isSelected",
            "referencekey",
          ])
        );
        return itm;
      }
    });

    let f = {
      // tenantid: 7,
      crn: this.selectedResourceName,
      fields: columns,
      // columns : columns,
      filters: this.filterValues,
      limit: this.pageSize,
      offset:
        this.pageIndex == 1
          ? 0
          : this.pageIndex * this.pageSize - this.pageSize,
    };
    // if (this.attrFilters.length > 0) {
    //   let filters = [];
    //   _.map(this.attrFilters, function (g, grpIdx) {
    //     let group = {};
    //     group[`group${grpIdx}`] = [];
    //     if (g.attributeList && g.attributeList.length > 0) {
    //       let obj = {
    //         groupcondition: g.operator == 0 ? 'AND' : 'OR',
    //         filters: []
    //       }
    //       g.attributeList.map((a) => {
    //         let f = {
    //           fieldname: a.type.fieldname,
    //           fieldvalue: a.value,
    //           formula: a.formula,
    //           fieldkey: a.type.fieldkey,
    //           fieldtype: a.type.fieldtype
    //         }
    //         if (a.type.fieldtype == 'DateTime') {
    //           f['fieldvalue'][0] = moment(a.value[0]).format('YYYY-MM-DD HH:mm:ss');
    //           f['fieldvalue'][1] = moment(a.value[1]).format('YYYY-MM-DD HH:mm:ss');
    //         }
    //         obj.filters.push(f);
    //       });
    //       group[`group${grpIdx}`].push(obj);
    //       filters.push(group)
    //     }
    //   });
    //   f['attrfilters'] = filters;
    // }
    f["status"] = AppConstant.STATUS.ACTIVE;
    let defaultSortExist=false;
    if(this.assetQryDetails.defaultSortby){
      if(this.assetQryDetails.defaultSortby.length > 0){
        if(this.assetQryDetails.defaultSortby[0]["name"]){
          f["sortkey"] = this.assetQryDetails.defaultSortby[0]["name"];
          f["sortorder"] = this.assetQryDetails.defaultSortby[0]["sortorder"];
        } 
      }
    }
    else if (!defaultSortExist && this.currentSortColumn) {
      f["sortkey"] = this.currentSortColumn;
      f["sortorder"] = this.currentSortColumnOrder;
    }

    // const tag = this.filterForm.get("tag").value;
    // const tagValue = this.filterForm.get("tagvalue").value;
    // if (tag) {
    //   f["tag"] = tag;
    // }
    // if (tag && tagValue) {
    //   f["tagvalue"] = tagValue;
    // }

    // if (this.globalSearchModel.length > 0) {
    //   f["search"] = this.globalSearchModel;
    //   f["limit"] = this.pageSize;
    //   f["offset"] = 0;
    // }
    if (selectedFields.length > 0) {
      if(download){
        delete f.limit;
        delete f.offset;
      }
      this.assetRecordService.getResourceAssets(f).subscribe((r) => {
        this.gettingAssets = false;

        let response: { count: number; rows: Record<string, any>[] } =
          JSON.parse(r._body);
        this.selectedFields = selectedFields;
        this.assetsCount = response.count;
        if(download){
          this.downloadCSV(this.selectedFields,response.rows);
        }
        else{
          this.assets = response.rows;
        }
      });
    } else {
      this.gettingAssets = false;
    }
  }
  downloadCSV(hdr, summary) {
    let data: any = [];
    let csvheaders = _.map(hdr, function (o: any) { return o.fieldname });
    let headers = _.map(hdr, function (o: any) { return o.fieldname });
    _.map(summary, function (value) {
      let obj = [];
      headers.map((o) => {
        if (value[o] != undefined) {
          obj.push(value[o])
        } else {
          obj.push('')
        }
      })
      data.push(obj);
    })
    console.log('result', data);
    var csv = Papa.unparse([[...csvheaders], ...data]);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    let name='';
    if(this.assetQryDetails){
      name=this.InstanceName+this.customerObj["customername"];
    }
    tempLink.setAttribute("download", `${name}-${moment().format('DD-MMM-YYYY')}.csv`);
    tempLink.click();
  }
}
