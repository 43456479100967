import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import en from "@angular/common/locales/en";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { NgModule } from "@angular/core";
import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { registerLocaleData } from "@angular/common";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { CustomerDashboardComponent } from "./components/customer-dashboard/customer-dashboard.component";
import { DashboardConfigService } from "./services/dashboard-config.service";
import { NotificationService } from "./services/global-notification.service";
import { HttpHandlerService } from "./services/http-handler.service";
import { IncidentService } from "./services/incidents.service";
import { PrometheusService } from "./services/prometheus.service";
import { TenantsService } from "./services/tenants.service";
import { DecimalPipe } from "@angular/common";
import { MetricsViewComponent } from "./components/metricsview/metricsview.component";
import { NtfBannerComponent } from "./components/notification-banner/notification-banner.component";
import { UptimeCalendarComponent } from "./components/uptime-calendar/uc.component";
import { KpiReportingComponent } from "./components/kpi-reporting/kpi-reporting.component";
import { NgApexchartsModule } from "ng-apexcharts";

import { AssetRecordService } from './services/assetrecords.service';
import { TagService } from './services/tags.service';
import { TagValueService } from './services/tagvalue.service';
import { CommonService } from './services/common.service';
import { AWSService } from './services/aws-service';

import { FiltersComponent } from './modules/components/filters/filters.component';
import { AdditionalFiltersComponent } from './modules/components/additional-filters/additional-filters.component';
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { LoginService } from "./components/login/login.service";
import { AuthenticationService } from "./authentication.service";
import { ResultErrorComponent } from "./components/permissiondenied.component";
import { CustomReportComponent } from "./components/custom-report/custom-report.component";
import { MultiSelectModule } from 'primeng/multiselect'
import { InvokeDirective } from "./components/customer-dashboard/invoke.directive";
import {DailyReportComponent} from './components/daily-report/dailyreport.component';
import { AssetRecordsComponent } from './components/assetrecords/assetrecords.component';
import { MaskTextComponent } from './components/contentmask/mask.component';
import { AppDSqlExeceptionComponent } from './components/appdynamics-chartwidgets/sql-exception/sqlexception.component';
registerLocaleData(en);

@NgModule({
  declarations: [
    InvokeDirective,
    AppComponent,
    HomeComponent,
    CustomerDashboardComponent,
    UptimeCalendarComponent,
    MetricsViewComponent,
    NtfBannerComponent,
    KpiReportingComponent,
    FiltersComponent,
    AdditionalFiltersComponent,
    LoginComponent,
    ResultErrorComponent,
    CustomReportComponent,
    DailyReportComponent,
    AssetRecordsComponent,
    MaskTextComponent,
    AppDSqlExeceptionComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    NgZorroAntdModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    FormsModule,
    MultiSelectModule
    // SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    DashboardConfigService,
    NotificationService,
    HttpHandlerService,
    IncidentService,
    DecimalPipe,
    PrometheusService,
    TenantsService,
    AssetRecordService,
    TagService,
    TagValueService,
    CommonService,
    AWSService,
    LoginService,
    AuthenticationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
