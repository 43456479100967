import { environment } from "../environments/environment";

export const AppConstant = Object.freeze({
  API_END_POINT: environment.baseURL,
  WEB_END_POINT: environment.weburl,
  LOCALSTORAGE: {
    STR_PREFIX: "cloudmatiq-customer-",
    TN_LOOKUP: "cloudmatiq-customer-tn-lookup",
    TOKEN: "token",
    USER: "user",
    ISAUTHENTICATED: "isAuthenticated",
    SCREENS: "screens",
    SESSION_TYPE: "styp",
    APPDYNAMIC_TOKEN:'appdtkn'
  },
  API_CONFIG: {
    API_URL: {
      LOGIN: "/users/login",
      VERIFYOTP: "/users/verifyotp",
      RESENDOTP: "/users/resendotp",
      FORGOT_PASSWOD: "/users/forgotpassword",
      RESET_PASSWORD: "/users/resetpassword",
      BASE: {
        INCIDENTS: {
          CREATE: "/incidents/create",
          FINDALL: "/incidents/list",
          UPDATE: "/incidents/update",
          FINDBYID: "/incidents/",
          DELETE: "/incidents/delete/",
        },
        PROMETHEUS: {
          VMSTATUS: "/prometheus/vmstatus",
          VMUPTIME: "/prometheus/uptime",
          CURRVMUPTIME: "/prometheus/currentuptime",
          KPISUMMARY: "/prometheus/kpisummary",
          COUNT: "/prometheus/alertcount",
          DATEWISECOUNT: "/prometheus/datewisealertcount",
          DATALIST: "/prometheus/datalist",
          CALENDAR:"/prometheus/calendar",
          METRIC_DTL : "/prometheus/metricdetails"
        },
        INSTANCE: {
          CHART: "/instances/chart",
        },
        ASSETRECORDS: {
          CMDBCHART: "/base/assetrecords/cmdbreport",
          CHART: "/base/assetrecords/chart",
          RESOURCETYPE: "/base/assetrecords/resourcetype",
          RESOURCE: "/base/assetrecords/resources/{type}", // type is crn
          RESOURCEDETAILS:"/base/assetrecords/resourcedetails",
          FINDALLDTL: "/base/assetrecords/resourcedetails/list",
          GETAPPDYNAMICSTOKEN : "/base/assetrecords/getAppdynamicToken",
          GETAPPDYNAMICDATA : "/base/assetrecords/getAppDynamicChartData",
          FINDALL: "/base/assetrecords/list",
          CUSTOMREPORT:"/base/assetrecords/customreport",
          CUSTOMREPORTDETAILS:"/base/assetrecords/customreport/details",
          CUSTOMREPORT_TASKS:"/base/assetrecords/customreport/tasks",
          TXNREFLIST:"/base/assetrecords/txnreflist",
          RESOURCEFIELDVALUES: "/base/assetrecords/resource/fieldvalues",
        },
        KPITREPORTING: {
          FINDALL: "/tenants/kpi/report/configheader/list",
          DETAILS: {
            FINDALL: "/tenants/kpi/report/configdetail/list",
          },
          CUSTOMER: {
            FINDALL: "/tenants/customer/kpi/list",
          },
          SYNTHETICS: "/syntheticmetric/chart",
          SYNTHETIC_REGIONS: "/syntheticmetric/regions",
          SSL: "/ssl/list",
        },
        EVENTLOG: {
          FINDALL: "/eventlog/list",
        },
        TAGS: {
          FINDALL: "/tags/list",
        },
        TAGVALUE: {
          FINDALL: "/tagvalues/list",
        },
        LOOKUP: {
          FINDALL: "/base/lookup/list",
        },
        INSTANCETYPE: {
          FINDALL: "/aws/insttype/list",
        },
      },
      NOTIFICATIONS: {
        CREATE: "/notifications/create",
        FINDALL: "/notifications/list",
        UPDATE: "/notifications/update",
        FINDBYID: "/notifications/",
      },
      TENANTS: {
        CHECKUSERPERM : "/users/permissioncheck",
        FINDALL: "/tenants/list",
        CREATE: "/tenants/create",
        UPDATE: "/tenants/update",
        FINDBYID: "/tenants/",
        DASHBOARD: "/tenants/dashboard",
        REGIONS: {
          FINDALL: "/tenants/region/list",
        },
        SOLUTIONS: {
          FINDALL: "/solutions/list",
        },
        SLA: {
          CREATE: "/tenants/sla/create",
          UPDATE: "/tenants/sla/update",
          BULKUPDATE: "/tenants/sla/bulkupdate",
          SLATEMPLATES: {
            CREATE: "/tenants/slatemplates/create",
            UPDATE: "/tenants/slatemplates/update",
            FINDBYID: "/tenants/slatemplates/",
            FINDALL: "/tenants/slatemplates/list",
            DELETE: "/tenants/slatemplates/delete/",
          },
          SERVICECREDITS: {
            CREATE: "/tenants/servicecredits/create",
            UPDATE: "/tenants/servicecredits/update",
            FINDBYID: "/tenants/servicecredits/",
            FINDALL: "/tenants/servicecredits/list",
            DELETE: "/tenants/servicecredits/delete/",
          },
          KPIUPTIME: {
            CREATE: "/tenants/kpiuptime/create",
            UPDATE: "/tenants/kpiuptime/update",
            FINDBYID: "/tenants/kpiuptime/",
            FINDALL: "/tenants/kpiuptime/list",
            DELETE: "/tenants/kpiuptime/delete/",
          },
          KPITICKETS: {
            CREATE: "/tenants/kpitickets/create",
            UPDATE: "/tenants/kpitickets/update",
            FINDBYID: "/tenants/kpitickets/",
            FINDALL: "/tenants/kpitickets/list",
            DELETE: "/tenants/kpitickets/delete/",
          },
        },
        CLIENT: {
          CREATE: "/customers/create",
          UPDATE: "/customers/update",
          FINDBYID: "/customers/",
          FINDALL: "/customers/list",
          UPLOAD: "/customers/upload",
          SLA: "/customers/sla/create",
          DASHBOARD_CONFIG: "/customers/dashboardconfig",
          VERIFYCUSTOMER:"/customers/verify",
          INCIDENTSLA: {
            UPDATE: "/tenants/customer/incidentsla/update",
          },
          AVAILABILITYSLA: {
            UPDATE: "/tenants/customer/availabilitysla/update",
          },
          SERVICECREDITS: {
            UPDATE: "/tenants/customer/servicecredits/update",
          },
        },
        CUSTOMER_ACCOUNT: {
          CREATE: "/customer-account/create",
          UPDATE: "/customer-account/update",
          FINDBYID: "/customer-account/",
          FINDALL: "/customer-account/list",
        },
        USERS: {
          FINDALL: "/users/list",
          CREATE: "/users/create",
          UPDATE: "/users/update",
          FINDBYID: "/users/",
          RESETTOTP: "/users/resettotp",
        },
        USERROLES: {
          FINDALL: "/users/role/list",
          CREATE: "/users/role/create",
          UPDATE: "/users/role/update",
          FINDBYID: "/users/role/",
        },
        INSTANCE: {
          FINDALL: "/instances/list",
          CREATE: "/instances/create",
          UPDATE: "/instances/update",
          FINDBYID: "/instances/",
        },
      },
      DASHBOARDCONFIG: {
        HDR: {
          FINDALL: "/base/dashboardconfigheader/list",
          CREATE: "/base/dashboardconfigheader/create",
          UPDATE: "/base/dashboardconfigheader/update",
          FINDBYID: "/base/dashboardconfigheader/",
          DELETE: "/base/dashboardconfigheader/delete/",
        },
        DTL: {
          FINDALL: "/base/dashboardconfigdetail/list",
          CREATE: "/base/dashboardconfigdetail/create",
          UPDATE: "/base/dashboardconfigdetail/update",
          FINDBYID: "/base/dashboardconfigdetail/",
        },
      },
    },
  },
  STATUS: {
    PENDING: "Pending",
    PUBLISHED: "Published",
    DELETED: "Deleted",
    UNPUBLISH: "Unpublish",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    CLOSED: "Completed",
    WIP: "Work In Progress",
    DRAFT: "Draft",
    DEPLOYED: "Deployed",
    CLOSEREQ: "Close Request",
    REJECTED: "Rejected",
    NOC: "NOC",
    FAILED: "Failed",
  },
  KPI_REPORING: {
    TICKETS: "TICKETS",
    MONITORING: "MONITORING",
    ASSET: "ASSET",
    CMDB: "CMDB",
    CMDB_SAVEDQUERY: "CMDB_SAVEDQUERY",
    SYNTHETICS: "SYNTHETICS",
    SSL: "SSL"
  },
  KPI_LABEL: {
    TICKETS: {
      XAXIS: "Incident Date",
      YAXIS: "Incidents Count",
    },
    MONITORING: {
      XAXIS: "Event Date",
      YAXIS: "Events Count",
    },
    ASSET: {
      XAXIS: "Created Date",
      YAXIS: "Assets Count",
    },
    CMDB: {
      XAXIS: "Created Date",
      YAXIS: "Assets Count",
    },
    SSL: {
      XAXIS: "Date",
      YAXIS: "Url count",
    },
  },
  CHART_TYPES: [
    { label: "Bar", value: "bar" },
    { label: "Line", value: "line" },
    { label: "Scatter", value: "scatter" },
  ],
  LOOKUPKEY: {
    TICKET_CATEGORY: "TICKET_CATEGORY",
    TICKET_SUBCATEGORY: "TICKET_SUBCATEGORY",
    TICKET_SEVERITY: "TICKET_SEVERITY",
    TICKET_STATUS: "TICKET_STATUS",
    TICKET_PRODUCT: "TICKET_PRODUCT",
    TICKET_URGENCY: "TICKET_URGENCY",
    TICKET_IMPACT: "TICKET_IMPACT",
    TICKET_CONTACTTYPE: "TICKET_CONTACTTYPE",
    TICKET_ASSIGN_GROUP: "TICKET_ASSIGN_GROUP",
    MONITORING_LEVELS: "MONITORINGLEVELS",
    CSTM_RPT_RAG:"CSTM_RPT_RAG",
    CUSTOM_REPORT_TTC:"CUSTOM_REPORT_TTC",
    DREPORT_WGT_FOLDER:"DREPORT_WGT_FOLDER",
    DREPORT_WGT:"DREPORT_WGT",
    TN_INT : "TN_INT",
  },
  MARS_DAYS: 9,
  MARS_CUSTOMERID: 374,
  NESLE_CUSTOMERID: 145,
  ALERT_LEVELS: {
    SYSTEM: [
      { title: "High", value: "High", count: 0 },
      { title: "Medium", value: "Medium", count: 0 },
      { title: "Low", value: "Low", count: 0 },
    ],
    SECURITY: [
      { title: "High", value: "High", count: 0 },
      { title: "Medium", value: "Medium", count: 0 },
      { title: "Low", value: "Low", count: 0 },
    ],
    EVENTS: [
      { title: "High", value: "High", count: 0 },
      // { title: "Normal", value: "Normal",count:0 },
      { title: "Medium", value: "Medium", count: 0 },
      { title: "Low", value: "Low", count: 0 },
    ],
    LEVELS: [
      { title: "Level 1 (Ignored)", value: 0 },
      { title: "Level 2 (System low priority notification)", value: 2 },
      { title: "Level 3 (Successful/Authorized events)", value: 3 },
      { title: "Level 4 (System low priority error)", value: 4 },
      { title: "Level 5 (User generated error)", value: 5 },
      { title: "Level 6 (Low relevance attack)", value: 6 },
      { title: "Level 7 ('Bad word' matching)", value: 7 },
      { title: "Level 8 (First time seen)", value: 8 },
      { title: "Level 9 (Error from invalid source)", value: 9 },
      { title: "Level 10 (Multiple user generated errors)", value: 10 },
      { title: "Level 11 (Integrity checking warning)", value: 11 },
      { title: "Level 12 (High importance event)", value: 12 },
      { title: "Level 13 (Unusual error (high importance))", value: 13 },
      { title: "Level 14 (High importance security event)", value: 14 },
      { title: "Level 15 (Severe attack)", value: 15 },
    ],
  },
  METRICS: [
    { title: "CPU", value: "CPU" },
    { title: "RAM", value: "RAM" },
    { title: "DISK", value: "DISK" },
  ],
  ACT_CLOUDPROVIDER: [
    { title: "AWS", value: "AWS" },
    { title: "Sentia", value: "Sentia" },
    { title: "Equinix", value: "Equinix" },
  ],
  AWS_CLOUDSTATUS: [
    { title: "Pending", value: "pending" },
    { title: "Running", value: "running" },
    { title: "Shutting-down", value: "shutting-down" },
    { title: "Stopped", value: "stopped" },
    { title: "Terminated", value: "terminated" },
  ],
  KPI: {
    TICKETSGROUPANDFILTERS: [
      {
        title: "Priority",
        value: "severity",
        cangroup: true,
        groupattr: "severity",
        selectedvalues: [],
        type: "TICKETS",
        filterable: true,
      },
      {
        title: "Category",
        value: "category",
        cangroup: true,
        groupattr: "category",
        selectedvalues: [],
        type: "TICKETS",
        filterable: true,
      },
      {
        title: "Published?",
        value: "publishyn",
        cangroup: true,
        groupattr: "publishyn",
        selectedvalues: [],
        type: "TICKETS",
        filterable: false,
      },
      {
        title: "Status",
        value: "incidentstatus",
        cangroup: true,
        groupattr: "incidentstatus",
        selectedvalues: [],
        type: "TICKETS",
        filterable: true,
      },
    ],
    ASSETGROUPANDFILTERS: [
      {
        title: "Provider",
        value: "cloudprovider",
        cangroup: true,
        groupattr: "severity",
        type: "ASSET",
        selectedvalues: [],
        filterable: false,
      },
      {
        title: "Instance",
        value: "instancename",
        cangroup: false,
        groupattr: "instancename",
        type: "ASSET",
        selectedvalues: [],
        filterable: true,
      },
      {
        title: "Instance Id",
        value: "instancerefid",
        cangroup: false,
        groupattr: "instancerefid",
        type: "ASSET",
        selectedvalues: [],
        filterable: true,
      },
      {
        title: "Region",
        value: "region",
        cangroup: true,
        groupattr: "region",
        type: "ASSET",
        selectedvalues: [],
        filterable: true,
      },
      {
        title: "Instance Type",
        value: "instancetyperefid",
        cangroup: true,
        groupattr: "instancetyperefid",
        type: "ASSET",
        selectedvalues: [],
        filterable: true,
      },
      {
        title: "Cloud Status",
        value: "cloudstatus",
        cangroup: true,
        groupattr: "cloudstatus",
        type: "ASSET",
        selectedvalues: [],
        filterable: false,
      },
    ],
    MONITORGROUPANDFILTERS: [
      {
        title: "Priority",
        value: "severity",
        cangroup: true,
        groupattr: "severity",
        type: "MONITORING",
        selectedvalues: [],
        filterable: false,
      },
      {
        title: "Alert Type",
        value: "referencetype",
        cangroup: true,
        groupattr: "referencetype",
        type: "MONITORING",
        selectedvalues: [],
        filterable: false,
      },
      {
        title: "Levels",
        value: "level",
        cangroup: true,
        groupattr: "level",
        type: "MONITORING",
        selectedvalues: [],
        filterable: true,
      },
      {
        title: "Metric",
        value: "metric",
        cangroup: true,
        groupattr: "metric",
        type: "MONITORING",
        selectedvalues: [],
        filterable: false,
      },
    ],
    CMDBGROUPANDFILTERS: [
      // {
      //   title: "Resource Type",
      //   value: "crn",
      //   cangroup: true,
      //   groupattr: "crn",
      //   type: "CMDB",
      //   selectedvalues: [],
      //   filterable: true
      // },
      {
        title: "Attribute",
        value: "attribute",
        cangroup: true,
        groupattr: "attribute",
        type: "CMDB",
        selectedvalues: [],
      },
      {
        title: "Tag",
        value: "tagid",
        cangroup: false,
        groupattr: "tagid",
        type: "CMDB",
        selectedvalues: [],
        filterable: true,
      },
      {
        title: "Tag Value",
        value: "tagvalue",
        cangroup: false,
        groupattr: "tagvalue",
        type: "CMDB",
        selectedvalues: [],
        filterable: true,
      },
    ],
    SSLGROUPANDFILTERS: [
      {
        title: "SSL name",
        value: "name",
        cangroup: true,
        groupattr: "name",
        selectedvalues: [],
        type: "SSL",
        filterable: true
      },
      {
        title: "URLS",
        value: "urls",
        cangroup: true,
        groupattr: "urls",
        selectedvalues: [],
        type: "SSL",
        filterable: true
      }
    ],
    TICKET_HEADER: [
      {
        header: "Incident Date",
        value: "x",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "Incident Count",
        value: "y",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "Priority",
        value: "severity",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Category",
        value: "category",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Published?",
        value: "publishyn",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Status",
        value: "incidentstatus",
        datatype: "string",
        showdefault: false,
      },
    ],
    MONITORING_HEADER: [
      {
        header: "Event Date",
        value: "x",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "Events Count",
        value: "y",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "Priority",
        value: "severity",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Alert Type",
        value: "referencetype",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Levels",
        value: "level",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Metric",
        value: "metric",
        datatype: "string",
        showdefault: false,
      },
    ],
    SSL_HEADER: [
      {
        header: "Date",
        value: "x",
        datatype: "timestamp",
        format: "dd-MMM-yyyy",
        showdefault: true,
      },
      {header: "Url count", value: "y", datatype: "string", showdefault: true},
      {
        header: "SSL Name",
        value: "name",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "URLS",
        value: "urls",
        datatype: "string",
        showdefault: true,
      }
    ],
    ASSET_HEADER: [
      {
        header: "Created Date",
        value: "x",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "Assets Count",
        value: "y",
        datatype: "string",
        showdefault: true,
      },
      {
        header: "Provider",
        value: "cloudprovider",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Instance",
        value: "instancename",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Instance Id",
        value: "instancerefid",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Region",
        value: "region",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Instance Type",
        value: "instancetyperefid",
        datatype: "string",
        showdefault: false,
      },
      {
        header: "Cloud Status",
        value: "cloudstatus",
        datatype: "string",
        showdefault: false,
      },
    ],
    CUSTOMREPORT_HEADER:[
      {
        header: 'Task',
        value: 'title',
        datatype: 'string',
        align:'left',
        showdefault: false
      },
      {
        header: 'Period',
        value: 'period',
        datatype: 'string',
        align:'left',
        showdefault: false
      },
      {
        header: 'Period',
        value: 'displayperiod',
        datatype: 'string',
        align:'center',
        showdefault: true
      },
      {
        header: 'Business TG Time to complete (Sec)',
        value: 'approved_ttc',
        datatype: 'string',
        align:'center',
        showdefault: true
      },
      {
        header: 'AVG Time to complete (Sec)',
        value: 'avg_ttc',
        datatype: 'string',
        align:'center',
        showdefault: true
      },
      {
        header: '# Jobs on time',
        value: 'jot',
        datatype: 'string',
        align:'center',
        showdefault: true
      },
      {
        header: '# Jobs not on time',
        value: 'jnot',
        datatype: 'string',
        align:'center',
        showdefault: true
      },
      {
        header: '% Jobs on time',
        value: 'percentage',
        datatype: 'string',
        align:'center',
        showdefault: true
      }
    ]
  },
  CMDB_OPERATIONTYPE:["cmdb","workpack-execution","workpack-review","workpack-template","workpack-task",'workpack-executable','task-executable','workpack-watchlist','asset-product-map'],
  ON_PREM_TENANTID:7
});
