import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { KPIReportingService } from "../../services/kpi-reporting.service";
import * as _ from "lodash";
import * as moment from "moment";
import { IncidentService } from "../../services/incidents.service";
import { EventLogService } from "../../services/eventlog.service";
import downloadService from "../../services/shared/download.service";
import { Buffer } from "buffer";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexTooltip
} from "ng-apexcharts";
import { AppConstant } from "src/app/app.constant";
import * as Papa from "papaparse";
import { Subscription } from "rxjs";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
};

@Component({
  selector: "app-kpi-reporting",
  templateUrl: "./kpi-reporting.component.html",
  styleUrls: ["./kpi-reporting.component.css"],
})
export class KpiReportingComponent implements OnInit {
  @Input() kpireportObj: any = [];
  @Input() customerObj: any = {};
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  reports = [];
  customerid;
  xaxis = [];
  ticketsummary = [];
  monitoringsummary = [];
  assetsummary = [];
  cmdbsummary = [];
  syntheticsummary = [];
  sslsummary = [];
  resourceTypes = [];
  kpitickets: any = [];
  kpimonitorings: any = [];
  kpiassets: any = [];
  kpicmdb: any = [];
  kpicustomcmdb: any = [];
  kpisynthetics: any = [];
  kpissl: any = [];
  defaultchartType = "line";
  chartTypes = AppConstant.CHART_TYPES;
  reporttype;
  enablefilter = false;
  filterableValues = [];
  reportdetails = {};
  openfilter = false;
  currentTab = 0;
  ticketgroupAndFilterByOptions = AppConstant.KPI.TICKETSGROUPANDFILTERS;
  assetgroupAndFilterByOptions = AppConstant.KPI.ASSETGROUPANDFILTERS;
  monitoringgroupAndFilterByOptions = AppConstant.KPI.MONITORGROUPANDFILTERS;
  cmdbGroupOptions = AppConstant.KPI.CMDBGROUPANDFILTERS;
  sslgroupAndFilterByOptions = AppConstant.KPI.SSLGROUPANDFILTERS;
  ticketHeader: any = AppConstant.KPI.TICKET_HEADER;
  monitoringHeader: any = AppConstant.KPI.MONITORING_HEADER;
  assetHeader: any = AppConstant.KPI.ASSET_HEADER;
  sslHeader: any = AppConstant.KPI.SSL_HEADER;
  cmdbHeader: any = [];
  isLoading = true;
  pageIndex: number = 1;
  limit = 50;
  offset = 0;
  download = false;
  availableregions = [];
  isregion = false;
  public subscriptions = new Subscription();
  constructor(
    private kpiReportingService: KPIReportingService,
    private route: ActivatedRoute,
    private incidentService: IncidentService,
    private eventLogService: EventLogService
  ) {
    this.route.params.subscribe((params) => {
      if (params["customerid"] !== undefined) {
        this.customerid = Number(params.customerid);
      }
    });
  }

  ngOnInit() {
    this.getReports();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kpireportObj && changes.kpireportObj.currentValue) {
      this.kpireportObj = changes.kpireportObj.currentValue;
    }
    if (changes.customerObj && changes.customerObj.currentValue) {
      this.customerObj = changes.customerObj.currentValue;
      this.subscriptions.add(this.getResourceType({ tenantid: this.customerObj.tenantid }));
    }
  }

  getReports() {
    this.reports = [];
    let condition: any = {
      _customerid: this.customerid,
      status: "Active",
      publishyn: "Y",
    };
    if (!_.isEmpty(this.kpireportObj)) {
      this.kpireportObj.map((e) => {
        if (e.id) {
          if (condition["ids"] == undefined) condition["ids"] = [];
          condition["ids"].push(e.id);
        }
        if (e.reporttype) {
          if (condition["reporttypes"] == undefined)
            condition["reporttypes"] = [];
          condition["reporttypes"].push(e.reporttype);
        }
      });
    }
    this.kpiReportingService
      .customerkpiall(condition, `?report=${true}`)
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.reports = response.data;
          // this.getConfiguredSeries(
          //   _.map(response.data, function (e) {
          //     return e._reportid;
          //   })
          // );
          let dashboardconfigDtls = [];
          response.data.map((e) => {
            if (e.configdetail) {
              dashboardconfigDtls = dashboardconfigDtls.concat(e.configdetail);
            }
          });
          if (dashboardconfigDtls.length > 0) {
            let self = this;
            _.map(dashboardconfigDtls, function (itm) {
              itm.startdate = moment().startOf("month").format("DD-MMM-YYYY");
              itm.enddate = moment().endOf("month").format("DD-MMM-YYYY");
              itm.selectedfilters = JSON.parse(itm.filterby);
              if (itm.reporttype == "TICKETS") {
                self.getTickets(itm);
              }
              if (itm.reporttype == "MONITORING") {
                self.getMonitoring(itm);
              }
              if (itm.reporttype == "ASSET") {
                self.getAssets(itm);
              }
              if (itm.reporttype == "CMDB") {
                self.getCMDB(itm);
              }
              if (itm.reporttype == "CMDB_SAVEDQUERY") {
                self.getCMDBSavedQuery(itm);
              }
              if (itm.reporttype == 'SYNTHETICS') {
                itm.startdate = moment().format('DD-MMM-YYYY');
                itm.enddate = moment().format('DD-MMM-YYYY');
                self.getSyntheticReport(itm);
              }
              if (itm.reporttype == 'SSL') {
                self.getSSLReport(itm);
              }
            });
          } else {
            this.isLoading = false;
          }
        } else {
          this.reports = [];
          this.isLoading = false;
        }
      });
  }

  getConfiguredSeries(ids) {
    let condition = {
      status: "Active",
      ids: [],
      reporttypes: [],
      _confighdrids: [],
    } as any;
    if (!_.isEmpty(this.kpireportObj)) {
      this.kpireportObj.map((e) => {
        if (e.id) {
          condition["ids"].push(e.id);
        }
        if (e.reporttype) {
          condition["reporttypes"].push(e.reporttype);
        }
        if (e._confighdrid) {
          condition["_confighdrids"].push(e._confighdrid);
        }
      });
    }
    if (condition["ids"].length == 0) delete condition["ids"];
    if (condition["reporttypes"].length == 0) delete condition["reporttypes"];
    if (condition["_confighdrids"].length == 0)
      condition["_confighdrids"] = ids;


    this.kpiReportingService.alldetails(condition).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        let self = this;
        let len = response.data.length;
        _.each(response.data, (itm) => {
          let selectedreport = _.find(self.reports, {
            _reportid: itm._confighdrid,
          });
          // itm.startdate = selectedreport.startdt;
          // itm.enddate = selectedreport.enddt;
          itm.startdate = moment().startOf('month').format('DD-MMM-YYYY');
          itm.enddate = moment().endOf('month').format('DD-MMM-YYYY');
          itm.selectedfilters = JSON.parse(itm.filterby)
          if (itm.reporttype == "TICKETS") {
            self.getTickets(itm);
          }
          if (itm.reporttype == "MONITORING") {
            self.getMonitoring(itm);
          }
          if (itm.reporttype == "ASSET") {
            self.getAssets(itm);
          }
          if (itm.reporttype == "CMDB") {
            self.getCMDB(itm);
          }
          if (itm.reporttype == 'SSL') {
            self.getSSLReport(itm);
          }
          len--;
          if (len == 0) {
            this.isLoading = false;
          }
        });
      }
    });
  }

  getTickets(details, date?) {
    this.isLoading = true;
    if (date) {
      details.startdate = date[0];
      details.enddate = date[1];
    }
    let condition = {
      tenantid: details.tenantid,
      customerid: this.customerid,
      status: details.status,
      duration: details.duration,
      startdate: moment(details.startdate).format("YYYY-MM-DD"),
      enddate: moment(details.enddate).format("YYYY-MM-DD"),
    };
    if (details.groupby && details.groupby != "") {
      condition["groupby"] = details.groupby;
    }
    if (!_.isEmpty(details.selectedfilters)) {
      condition["filters"] = condition["filters"] ? condition["filters"] : [];
      _.map(details.selectedfilters, function (value, key) {
        if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
          let obj = {};
          obj[key] = value;
          let existIdx = _.findIndex(condition["filters"], function (o) { if (o[key]) { return o } });
          if (existIdx != -1) {
            condition["filters"][existIdx] = obj;
          } else {
            condition["filters"].push(obj);
          }
        }
      });
    }
    this.incidentService
      .all(condition, `?chart=${true}`)
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.ticketsummary = response.data;
          this.getChartSeries(details, response.data);
        } else {
          this.getChartSeries(details, []);
        }
        this.isLoading = false;
      });
  }

  getMonitoring(details, date?) {
    this.isLoading = true;
    if (date) {
      details.startdate = date[0];
      details.enddate = date[1];
    }
    let condition = {
      tenantid: details.tenantid,
      customerid: this.customerid,
      status: details.status,
      duration: details.duration,
      startdt: moment(details.startdate).format("YYYY-MM-DD"),
      enddt: moment(details.enddate).format("YYYY-MM-DD"),
    };
    if (details.groupby) {
      condition["groupby"] = details.groupby;
    }
    // if (!_.isEmpty(JSON.parse(details.filterby))) {
    //   condition["filters"] = [];
    //   let self = this;
    //   _.map(JSON.parse(details.filterby), function (value, key) {
    //     if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
    //       let obj = {};
    //       obj[key] = value;
    //       condition["filters"].push(obj);
    //     }
    //   });
    // }
    if (!_.isEmpty(details.selectedfilters)) {
      condition["filters"] = condition["filters"] ? condition["filters"] : [];
      let self = this;
      _.map(details.selectedfilters, function (value, key) {
        if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
          let obj = {};
          obj[key] = value;
          condition["filters"].push(obj);
        }
      });
    }
    this.eventLogService
      .all(condition, `?chart=${true}`)
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.monitoringsummary = response.data;
          this.getChartSeries(details, response.data);
        } else {
          this.getChartSeries(details, []);
        }
        this.isLoading = false;
      });
  }
  getSSLReport(details, date?) {
    this.isLoading = true;
    if (date) {
      details.startdate = date[0];
      details.enddate = date[1];
    }
    let condition = {
      tenantid: details.tenantid,
      status: details.status,
      customerid: this.customerid,
      duration: details.duration,
      startdt: moment(details.startdate).format("YYYY-MM-DD"),
      enddt: moment(details.enddate).format("YYYY-MM-DD"),
    };
    if (details.groupby) {
      condition["groupby"] = details.groupby;
    }
    if (!_.isEmpty(details.selectedfilters)) {
      condition["filters"] = condition["filters"] ? condition["filters"] : [];
      let self = this;
      _.map(details.selectedfilters, function (value, key) {
        if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
          let obj = {};
          obj[key] = value;
          condition["filters"].push(obj);
        }
      });
    }
    this.kpiReportingService.getAllssl(condition, `?chart=${true}`).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.sslsummary = response.data;
        this.getChartSeries(details, response.data);
      } else {
        this.getChartSeries(details, []);
      }
      this.isLoading = false;
    });
  }
  getAssets(details, date?) {
    this.isLoading = true;
    if (date) {
      details.startdate = date[0];
      details.enddate = date[1];
    }
    let condition = {
      tenantid: details.tenantid,
      status: details.status,
      duration: details.duration,
      customerid: this.customerid,
      startdt: moment(details.startdate).format("YYYY-MM-DD"),
      enddt: moment(details.enddate).format("YYYY-MM-DD"),
    };
    if (details.groupby) {
      condition["groupby"] = details.groupby;
    }
    // if (!_.isEmpty(JSON.parse(details.filterby))) {
    //   condition["filters"] = {};
    //   let self = this;
    //   _.map(JSON.parse(details.filterby), function (value, key) {
    //     if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
    //       let obj = {};
    //       obj[key] = _.map(value, function (i) {
    //         return i.value;
    //       });
    //       condition["filters"][key] = _.map(value, function (i) {
    //         return i.value;
    //       });
    //     }
    //   });
    // }
    if (!_.isEmpty(details.selectedfilters)) {
      condition["filters"] = condition["filters"] ? condition["filters"] : [];
      let self = this;
      _.map(details.selectedfilters, function (value, key) {
        if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
          let obj = {};
          obj[key] = _.map(value, function (i) {
            return i.value;
          });
          condition["filters"] = obj
        }
      });
    }
    this.kpiReportingService.getassets(condition).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.assetsummary = response.data;
        this.getChartSeries(details, response.data);
      } else {
        this.getChartSeries(details, []);
      }
      this.isLoading = false;
    });
  }

  getCMDBSavedQuery(data, limit?, offset?, idx?) {
    if (idx) {
      this.kpicustomcmdb[idx]['loading'] = true;
    }
    if (limit === undefined && offset === undefined) {
      this.pageIndex = 1;
    }
    const settings = JSON.parse(data.settings);
    let req = {
      fields: [],
      charttype: data.charttype,
      tenantid: data.tenantid,
      savedqueryid: data.savedqueryid,
    }
    req.fields.push({
      fieldname: settings['xaxis']["fieldname"],
      fieldkey: settings['xaxis']["fieldkey"],
      fieldtype: settings['xaxis']['fieldtype'],
      crn: settings['xaxis']['crn'],
      header: settings['xaxis']["fieldname"]
    });
    if (settings.yaxisList.length > 0) {
      settings.yaxisList.map((e) => {
        e = e.yaxis;
        req.fields.push({
          fieldname: e["fieldname"],
          fieldkey: e["fieldkey"],
          fieldtype: e['fieldtype'],
          crn: e['crn'],
          header: e["fieldname"]
        });
      })
    }
    req["settings"] = settings;
    if (this.download) {
      req["isdownload"] = this.download;
      req["header"] = data.cmdbHeader;
    }
    let q = `?limit=${limit ? limit : this.limit}&offset=${offset ? offset : this.offset}`;
    if (data.istable) q = `?count=${true}`;
    this.subscriptions.add(this.kpiReportingService.getCMDBQueryReport(req, q).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (this.download) {
        var buffer = Buffer.from(response.data.content.data);
        downloadService(
          buffer,
          `CMDB-${moment().format("DD-MMM-YYYY")}.csv`
        );
      } else {
        data.totalCount = response.count;
        data.currentTab = 0;
        data.loading = false;
        if (data.istable) {
          data.data = response.rows;
          data.currentTab = 1;
          data.customcmdbHeader = [];
          data.customcmdbHeader = [{
            field: 'x',
            header: settings.xaxis.fieldname,
            datatype: "string",
            show: true
          }];

          settings.yaxisList.map((y, i) => {
            data.customcmdbHeader.push({
              field: `y${i}`,
              header: y.aggregate + `(${y.yaxis.fieldname})`,
              datatype: "string",
              show: true,
              fieldname: y.yaxis.fieldname,
              fieldtype: y.yaxis.fieldtype,
              fieldkey: y.yaxis.fieldkey
            },
              {
                field: `y${i}value`,
                header: y.yaxis.fieldname,
                fieldname: y.yaxis.fieldname,
                datatype: "string",
                show: true,
                fieldtype: y.yaxis.fieldtype,
                fieldkey: y.yaxis.fieldkey
              },
            )
          });
          this.kpicustomcmdb[idx].details = data;
        } else {
          data.xaxislabel = settings.xaxisname;
          let series = settings.yaxisList.map((e) => ({
            name: e.seriesname,
            data: []
          }));
          series.forEach((s, i) => {
            response.rows.forEach((e) => {
              s.data.push(e[`y${i}`])
            });
          });
          data.categories = response.rows.map((e) => e.x);
          this.kpicustomcmdb.push(this.prepareChartData(series, data));
        }

        if (idx) this.kpicustomcmdb[idx]['loading'] = false;
      }
      if (idx) this.kpicustomcmdb[idx]['loading'] = false;
      this.isLoading = false;
    }));
  }

  getRegions(details) {
    let exist = _.findIndex(this.kpisynthetics, function (e: any) { return e.details.id == details.id });
    let condition = {
    };
    if (!_.isEmpty(details.filterby)) {
      condition["filters"] = [JSON.parse(details.filterby)];
    }
    this.subscriptions.add(this.kpiReportingService.getSyntheticRegions(condition).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response) {
        this.availableregions = _.map(response.data, function (e) {
          return {
            label: e,
            value: e
          }
        })
        if (exist != -1) {
          this.kpisynthetics[exist]['details']['availableregions'] = this.availableregions;
        }
      }
    }));

  }

  changeRegion(i, event) {
    this.isregion = true;
    this.kpisynthetics[i].details.region = event;
    this.getSyntheticReport(this.kpisynthetics[i].details);
  }

  getSyntheticReport(details, date?, limit?, offset?) {
    try {
      details.currentTab = details && details.currentTab ? details.currentTab : 0;
      let exist = _.findIndex(this.kpisynthetics, function (e: any) { return e.details.id == details.id });
      if (exist != -1) { this.kpisynthetics[exist]['loading'] = true } else {
        this.isLoading = true;
      }
      console.log(details)
      if (date) {
        details.startdate = date[0];
        details.enddate = date[1];
        details.date = [moment(details.startdate).format('DD-MMM-YYYY'), moment(details.enddate).format('DD-MMM-YYYY')]
      } else {
        details.date = [moment(details.startdate).format('DD-MMM-YYYY'), moment(details.enddate).format('DD-MMM-YYYY')]
      }
      if (limit == undefined && offset == undefined) this.pageIndex = 1;
      let condition = {
        tenantid: details.tenantid,
        status: details.status,
        startdate: moment(details.startdate).format("YYYY-MM-DD") + ' 00:00:00',
        enddate: moment(details.enddate).format("YYYY-MM-DD") + ' 23:59:59',
      };
      const settings = JSON.parse(details.settings);
      if (!_.isEmpty(details.settings)) {
        condition["settings"] = JSON.parse(details.settings);
        delete condition["settings"]['limit'];
      }
      if (!_.isEmpty(details.filterby)) {
        condition["filters"] = [JSON.parse(details.filterby)];
        if (details.region) {
          condition["filters"].push({ region: details.region });
        }
      }
      this.subscriptions.add(this.kpiReportingService.getSyntheticReport(condition).subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response) {
          if (this.download) {
            var buffer = Buffer.from(response.data.content.data);
            downloadService(
              buffer,
              `CMDB-${moment().format("DD-MMM-YYYY")}.csv`
            );
          } else {
            this.syntheticsummary = response;
            details.totalCount = response.count;
            this.isLoading = false;
            if (exist != -1) this.kpisynthetics[exist]['loading'] = false;
            response.chart.map((e) => {
              if (e.name && condition["filters"][0]['url']) {
                let url = _.find(condition["filters"][0]['url'], { value: e.name });
                if (url) {
                  e.name = url['title'];
                }
              }
              return e;
            });
            details.syntheticHeader = [];
            details.syntheticHeader = [{
              field: settings.xaxis.fieldkey,
              header: settings.xaxis.fieldname,
              datatype: "string",
              show: true
            }];
            let isdateime = settings.xaxis.fieldkey == 'createddt' ? true : false;
            settings.yaxisList.map((y, i) => {
              details.syntheticHeader.push({
                field: y.yaxis.fieldkey,
                header: y.yaxis.fieldname,
                datatype: "string",
                show: true
              }
              )
            });
            details.data = [];
            let yaxis_rs = _.find(settings.yaxisList, function (e) { return e.yaxis.fieldkey == 'responsetime' });
            if (yaxis_rs) {
              details.syntheticHeader.push({
                field: 'url',
                header: 'URL',
                datatype: "string",
                show: true,
                fieldname: 'URL',
                fieldkey: 'url'
              }, {
                field: 'region',
                header: 'Region',
                datatype: "string",
                show: true,
                fieldname: 'Region',
                fieldkey: 'region'
              });
            }
            response['details'].map((r) => {
              let obj = {};
              obj[settings.xaxis.fieldkey] = settings.xaxis.fieldkey == 'createddt' ? moment(r['_id'][settings.xaxis.fieldkey]).format('DD-MMM-YYYY hh:mm:ss') : r['_id'][settings.xaxis.fieldkey];
              settings.yaxisList.map((e) => {
                if (e.yaxis.fieldkey == 'responsetime') {
                  obj[e.yaxis.fieldkey] = r['_id'][e.yaxis.fieldkey] + 'ms';
                  obj['url'] = r['_id']['url'];
                }
                else if (e.yaxis.fieldvalue == 'PASSED') obj[e.yaxis.fieldkey] = r[e.yaxis.fieldname];
                else if (e.yaxis.fieldvalue == 'FAILED') obj[e.yaxis.fieldkey] = r[e.yaxis.fieldname];
              });
              obj['region'] = (r['_id']['region']) ? r['_id']['region'] : ''
              details.data.push(obj)
            });
            if (exist != -1) {
              this.kpisynthetics[exist] = this.prepareChartData(response.chart, details, isdateime);
            } else {
              this.kpisynthetics.push(this.prepareChartData(response.chart, details, isdateime));
            }
            console.log(this.kpisynthetics)
            if (!this.isregion) this.getRegions(details);
          }
        } else {
          this.isLoading = false;
          if (exist != -1) this.kpisynthetics[exist]['loading'] = false;
          this.getChartSeries(details, []);
          this.isLoading = false;
        }
      }));
    } catch (e) {
      console.log('catch', e)
    }
  }

  getCMDB(details, date?, limit?, offset?) {
    let exist = _.findIndex(this.kpicmdb, function (e: any) { return e.details.id == details.id });
    if (exist != -1) { this.kpicmdb[exist]['loading'] = true } else {
      this.isLoading = true;
    }
    if (date) {
      details.startdate = date[0];
      details.enddate = date[1];
    }
    if (limit == undefined && offset == undefined) this.pageIndex = 1;
    let condition = {
      tenantid: details.tenantid,
      status: details.status,
      duration: details.duration,
      startdt: moment(details.startdate).format("YYYY-MM-DD"),
      enddt: moment(details.enddate).format("YYYY-MM-DD"),
    };
    condition["filters"] = {};
    if (details.selectedValues && details.selectedValues.length > 0) {
      condition["fieldvalues"] = details.selectedValues;
    }
    if (details.groupby) {
      condition["groupby"] = details.groupby;
    }
    if (details.charttype) {
      condition["charttype"] = details.charttype;
    }
    if (!_.isEmpty(JSON.parse(details.filterby))) {
      condition["filters"] = {};
      let self = this;
      let filters = JSON.parse(details.filterby);
      _.map(JSON.parse(details.filterby), function (value, key) {
        if (key != "attribute" && key != 'attributes') {
          if ((!_.isEmpty(value) && value.value != "") || value.value != null) {
            let obj = {};
            obj[key] = _.map(value, function (i) {
              return i.value;
            });
            condition["filters"][key] = _.map(value, function (i) {
              return i.value;
            });
          }
        }
        if (key == "attribute") {
          condition["attributes"] = value;
        }
        if (key == "settings") {
          condition["settings"] = value;
        }
      });
      if (filters.crn) {
        condition["filters"]['crn'] = filters.crn;
      }
      if (details.selectedattributes) {
        condition["attributes"] = details.selectedattributes;
      }
    }

    if (!_.isEmpty(details.settings)) {
      condition["settings"] = JSON.parse(details.settings);
    }

    if (this.download) {
      condition["isdownload"] = this.download;
      condition["header"] = details.cmdbHeader;
    }
    let q = `?limit=${limit ? limit : this.limit}&offset=${offset ? offset : this.offset}`;
    if (details.istable) q = q + `&count=${true}`;
    this.subscriptions.add(this.kpiReportingService.getcmdb(condition, q).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        if (this.download) {
          var buffer = Buffer.from(response.data.content.data);
          downloadService(
            buffer,
            `CMDB-${moment().format("DD-MMM-YYYY")}.csv`
          );
        } else {
          this.cmdbsummary = response.data.data;
          details.totalCount = response.data.count;
          if (limit == undefined && offset == undefined) this.getChartSeries(details, response.data.data);
          if (exist != -1) this.kpicmdb[exist]['loading'] = false;
        }
        if (exist != -1) this.kpicmdb[exist]['loading'] = false;
        this.isLoading = false;
      } else {
        this.getChartSeries(details, []);
        if (exist != -1) this.kpicmdb[exist]['loading'] = false;
        this.isLoading = false;
      }
    }));
  }

  dateArrayFormat(configdtl, data) {
    let result = [];
    let duration = configdtl.duration;

    if (duration == "Daily") {
      let startdt = new Date(configdtl.startdate);
      let enddt = new Date(configdtl.enddate);
      let differnce = moment(enddt).diff(moment(startdt), "days");
      var i = 0;
      let baseval = startdt.getTime();
      while (i <= differnce) {
        var y = 0;
        this.xaxis[i] = baseval;
        result[i] = { x: moment(baseval).format("DD-MMM-YYYY"), y: y };
        _.map(data, (itm) => {
          if (moment(baseval).format("DD-MMMM-YYYY") == itm.x) {
            result[i] = {
              x: moment(this.xaxis[i]).format("DD-MMM-YYYY"),
              y: itm.y,
            };
          }
        });
        baseval += 86400000;
        i++;
      }
      return result;
    } else {
      let details;
      if (configdtl.reporttype == AppConstant.KPI_REPORING.MONITORING)
        details = this.monitoringsummary;
      if (configdtl.reporttype == AppConstant.KPI_REPORING.TICKETS)
        details = this.ticketsummary;
      if (configdtl.reporttype == AppConstant.KPI_REPORING.ASSET)
        details = this.assetsummary;
      if (configdtl.reporttype == AppConstant.KPI_REPORING.CMDB)
        details = this.cmdbsummary;
      if (configdtl.reporttype == AppConstant.KPI_REPORING.SSL)
        details = this.sslsummary;
      let xaxis = _.map(details, function (itm) {
        return itm.x;
      });
      xaxis = _.uniq(xaxis);
      for (let i = 0; i < xaxis.length; i++) {
        let y = _.find(data, function (el) {
          return el.x == xaxis[i] ? el.y : 0;
        });
        result.push({
          x: xaxis[i],
          y: y ? y.y : 0,
        });
      }
      return result;
    }
  }
  getChartSeries(details, data) {
    let series = [
      // {
      //   name: details.seriesname,
      //   data: [],
      // },
    ];
    let key;
    if (data && data.length > 0) {
      if (details["groupby"] != null && details["groupby"] != "") {
        series = [];
        key = details["groupby"];
        if (details["groupby"] == "customerid") key = "customername";
        if (details.groupby == "_customer") key = "customername";
        if (details.groupby == "tagid") key = "tagname";
        let groupedItems = _.groupBy(data, key);
        let self = this;
        _.map(groupedItems, function (value, key) {
          if (details.groupby == "publishyn") {
            if (key == "Y") key = "Published";
            if (key == "N") key = "Not Published";
          }
          if (details.groupby == "crn") {
            _.map(value, function (e: any) {
              let resource = _.find(self.resourceTypes, { crn: e.crn });
              if (resource) {
                key = resource.resource;
              }
            });
          }
          series.push({
            name: key,
            data: self.dateArrayFormat(details, value),
          });
        });
      } else {
        series = [
          {
            name: details.seriesname,
            data: this.dateArrayFormat(details, data),
          },
        ];
      }
    }
    details.charttype = details.charttype ? details.charttype : this.defaultchartType;

    if (details.reporttype == AppConstant.KPI_REPORING.TICKETS) {
      details.xaxislabel = AppConstant.KPI_LABEL.TICKETS.XAXIS;
      details.yaxislabel = AppConstant.KPI_LABEL.TICKETS.YAXIS;
      details.currentTab = details && details.currentTab ? details.currentTab : 0;
      details.ticketHeader = _.filter(this.ticketHeader, function (e) {
        if (e.value == key) { e.showdefault = true; }
        if (e.value == 'x' || e.value == 'y' || e.value == key) {
          return e
        }
      });
      details.data = data;
      if (data.date == undefined) details.date = [moment().startOf('month').format('DD-MMM-YYYY'), moment().endOf('month').format('DD-MMM-YYYY')];
      let exist = _.findIndex(this.kpitickets, function (e: any) { return e.details.id == details.id });
      if (exist != -1) {
        details.date = this.kpitickets[exist].chart.date;
        this.kpitickets[exist] = this.prepareChartData(series, details);
      } else {
        this.kpitickets.push(this.prepareChartData(series, details));
      }
      this.kpitickets = _.sortBy(this.kpitickets, function (o) { return o.details.id });
    }
    if (details.reporttype == AppConstant.KPI_REPORING.MONITORING) {
      details.xaxislabel = AppConstant.KPI_LABEL.MONITORING.XAXIS;
      details.yaxislabel = AppConstant.KPI_LABEL.MONITORING.YAXIS;
      details.currentTab = details && details.currentTab ? details.currentTab : 0;
      details.monitoringHeader = _.filter(this.monitoringHeader, function (e) {
        if (e.value == key) { e.showdefault = true; }
        if (e.value == 'x' || e.value == 'y' || e.value == key) {
          return e
        }
      });
      details.data = data;
      if (data.date == undefined) details.date = [moment().startOf('month').format('DD-MMM-YYYY'), moment().endOf('month').format('DD-MMM-YYYY')];
      let exist = _.findIndex(this.kpimonitorings, function (e: any) { return e.details.id == details.id });
      if (exist != -1) {
        details.date = this.kpimonitorings[exist].chart.date;
        this.kpimonitorings[exist] = this.prepareChartData(series, details);
      } else {
        this.kpimonitorings.push(this.prepareChartData(series, details));
      }
      this.kpimonitorings = _.sortBy(this.kpimonitorings, function (o) { return o.details.id });
    }
    if (details.reporttype == AppConstant.KPI_REPORING.SSL) {
      details.xaxislabel = AppConstant.KPI_LABEL.SSL.XAXIS;
      details.yaxislabel = AppConstant.KPI_LABEL.SSL.YAXIS;
      details.currentTab = details && details.currentTab ? details.currentTab : 0;
      details.sslHeader = _.filter(this.sslHeader, function (e) {
        if (e.value == key) { e.showdefault = true; }
        if (e.value == 'x' || e.value == 'y' || e.value == key) {
          return e
        }
      });
      details.data = data;
      if (data.date == undefined) details.date = [moment().startOf('month').format('DD-MMM-YYYY'), moment().endOf('month').format('DD-MMM-YYYY')];
      let exist = _.findIndex(this.kpissl, function (e: any) { return e.details.id == details.id });
      if (exist != -1) {
        details.date = this.kpissl[exist].chart.date;
        this.kpissl[exist] = this.prepareChartData(series, details);
      } else {
        this.kpissl.push(this.prepareChartData(series, details));
      }
      this.kpissl = _.sortBy(this.kpissl, function (o) { return o.details.id });
    }
    if (details.reporttype == AppConstant.KPI_REPORING.ASSET) {
      details.xaxislabel = AppConstant.KPI_LABEL.ASSET.XAXIS;
      details.yaxislabel = AppConstant.KPI_LABEL.ASSET.YAXIS;
      details.currentTab = details && details.currentTab ? details.currentTab : 0;
      details.assetHeader = _.filter(this.assetHeader, function (e) {
        if (e.value == key) { e.showdefault = true; }
        if (e.value == 'x' || e.value == 'y' || e.value == key) {
          return e
        }
      });
      details.data = data;
      if (data.date == undefined) details.date = [moment().startOf('month').format('DD-MMM-YYYY'), moment().endOf('month').format('DD-MMM-YYYY')];
      let exist = _.findIndex(this.kpiassets, function (e: any) { return e.details.id == details.id });
      if (exist != -1) {
        details.date = this.kpiassets[exist].chart.date;
        this.kpiassets[exist] = this.prepareChartData(series, details);
      } else {
        this.kpiassets.push(this.prepareChartData(series, details));
      }
      this.kpiassets = _.sortBy(this.kpiassets, function (o) { return o.details.id });
    }
    if (details.reporttype == AppConstant.KPI_REPORING.CMDB) {
      let cmdbdetails = [];
      if (details.date == undefined) details.date = [moment().startOf('month').format('DD-MMM-YYYY'), moment().endOf('month').format('DD-MMM-YYYY')];
      details.currentTab = details && details.currentTab ? details.currentTab : 0;
      details.data = data;
      if (details.charttype != 'rangeBar') {
        details.xaxislabel = AppConstant.KPI_LABEL.CMDB.XAXIS;
        // details.yaxislabel = AppConstant.KPI_LABEL.CMDB.YAXIS;
        let settings = JSON.parse(details.settings);
        details.cmdbHeader = [];
        details.cmdbHeader.push({
          header: settings.xaxis.fieldname,
          value: 'x',
          datatype: "string",
          showdefault: true
        });
        settings.yaxisList.map((o, i) => {
          details.cmdbHeader.push({
            header: o.seriesname + (o.aggregate ? ` (${o.aggregate})` : ''),
            value: `y${i}`,
            datatype: "string",
            showdefault: true
          });
        });
        if (settings) {
          details.xaxislabel = settings.xaxis.fieldname;
        }
        if (settings.yaxisList && settings.yaxisList.length > 0) {
          let resData = data;
          settings.yaxisList.map((e, i) => {
            if (resData.length != 0) {
              let obj = {
                name: e.seriesname,
                data: [],
              };
              resData.map((o) => {
                obj.data.push({
                  x: (settings.xaxis.fieldtype == 'REFERENCE') ? (JSON.parse(o.x)[0].name) : o.x,
                  y: o[`y${i}`],
                });
              });
              cmdbdetails.push(obj);
            }

          });
        }

      } else {
        let settings = JSON.parse(details.settings);
        details.cmdbHeader = [];
        details.cmdbHeader.push({
          header: settings.yaxis.fieldname,
          value: 'x',
          datatype: "string",
          showdefault: true
        }, {
          header: 'Task A Name',
          value: 'label1',
          datatype: "string",
          showdefault: true
        }, {
          header: 'Task B Name',
          value: 'label2',
          datatype: "string",
          showdefault: true
        });
        settings.xaxisList.map((o, i) => {
          details.cmdbHeader.push({
            header: o.xaxis.fieldname + (o.aggregate ? ` (${o.aggregate})` : ''),
            value: i == 0 ? 'timestart' : 'timeend',
            datatype: "timestamp",
            format: "dd-MMM-yyyy HH:mm:ss",
            showdefault: true
          });
        });
        // Temp fix
        details.data = details.data.map((o) => {
          const fromDay = new Date(o['y0']).toISOString();
          const toDay = new Date(o['y1']).toISOString();
          const day1 = fromDay.substring(0, 10);
          const timestamp1 = fromDay.substring(11, 19);
          const day2 = toDay.substring(0, 10);
          const timestamp2 = toDay.substring(11, 19);
          o['timestart'] = moment(day1).format('DD-MMM-YYYY') + ' ' + timestamp1;
          o['timeend'] = moment(day2).format('DD-MMM-YYYY') + ' ' + timestamp2;
          return o;
        })
        let label1 = data.map((e) => {
          return e.label1;
        });
        let label2 = data.map((e) => {
          return e.label2;
        });

        let tasks = label1.concat(label2);
        tasks = _.uniq(tasks);
        let dataArr = tasks.map((e) => {
          return {
            name: e,
            data: [],
          };
        });

        let groupedProjects = _.groupBy(data, "x");

        details.totalprojects = Object.keys(groupedProjects).length;

        _.map(groupedProjects, function (value, key) {
          let i = 0;
          value.map((e) => {
            let existlabel1 = _.findIndex(dataArr, { name: e.label1 });
            if (existlabel1 != -1) {
              if (value.length > 1 && i > 0) {
                let valIndex = i - 1;
                if (value[valIndex]["y1"] != null && e.y0 != null) {
                  dataArr[existlabel1].data.push({
                    x: key,
                    y: [
                      new Date(value[valIndex]["y1"]).getTime(),
                      new Date(e.y0).getTime(),
                    ],
                  });
                }
              } else {
                if (e.y0 != null) {
                  dataArr[existlabel1].data.push({
                    x: key,
                    y: [new Date(e.y0).getTime(), new Date(e.y0).getTime()],
                  });
                }
              }
            }

            let existlabel2 = _.findIndex(dataArr, { name: e.label2 });
            if (existlabel2 != -1) {
              if (e.y0 != null && e.y1 != null) {
                dataArr[existlabel2].data.push({
                  x: key,
                  y: [new Date(e.y0).getTime(), new Date(e.y1).getTime()],
                });
              }
            }
            i++;
          });
        });
        cmdbdetails = dataArr;
      }
      let exist = _.findIndex(this.kpicmdb, function (e: any) { return e.details.id == details.id });
      if (exist != -1) {
        details.date = this.kpicmdb[exist].chart.date;
        this.kpicmdb[exist] = this.prepareChartData(cmdbdetails, details);
      } else {
        this.kpicmdb.push(this.prepareChartData(cmdbdetails, details));
      }
      this.kpicmdb = _.sortBy(this.kpicmdb, function (o) { return o.details.id });
    }
  }

  changeChartType(type, i, event) {
    if (type == AppConstant.KPI_REPORING.TICKETS) {
      this.kpitickets[i]["details"]["charttype"] = event;
      this.kpitickets[i] = this.prepareChartData(
        this.kpitickets[i]["series"],
        this.kpitickets[i]["details"]
      );
    }
    if (type == AppConstant.KPI_REPORING.MONITORING) {
      this.kpimonitorings[i]["details"]["charttype"] = event;
      this.kpimonitorings[i] = this.prepareChartData(
        this.kpimonitorings[i]["series"],
        this.kpimonitorings[i]["details"]
      );
    }
    if (type == AppConstant.KPI_REPORING.ASSET) {
      this.kpiassets[i]["details"]["charttype"] = event;
      this.kpiassets[i] = this.prepareChartData(
        this.kpiassets[i]["series"],
        this.kpiassets[i]["details"]
      );
    }
    if (type == AppConstant.KPI_REPORING.CMDB) {
      this.kpicmdb[i]["details"]["charttype"] = event;
      this.kpicmdb[i] = this.prepareChartData(
        this.kpicmdb[i]["series"],
        this.kpicmdb[i]["details"]
      );
    }
    if (type == AppConstant.KPI_REPORING.CMDB_SAVEDQUERY) {
      this.kpicustomcmdb[i]["details"]["charttype"] = event;
      this.kpicustomcmdb[i] = this.prepareChartData(
        this.kpicustomcmdb[i]["series"],
        this.kpicustomcmdb[i]["details"]
      );
    }
    if (type == AppConstant.KPI_REPORING.SYNTHETICS) {
      this.isregion = false;
      this.kpisynthetics[i]["details"]["charttype"] = event;
      this.kpisynthetics[i] = this.prepareChartData(
        this.kpisynthetics[i]["series"],
        this.kpisynthetics[i]["details"]
      );
    }
    if (type == AppConstant.KPI_REPORING.SSL) {
      this.kpissl[i]["details"]["charttype"] = event;
      this.kpissl[i] = this.prepareChartData(
        this.kpissl[i]["series"],
        this.kpissl[i]["details"]
      );
    }
  }

  prepareChartData(series, details, isdateime?) {
    if (details.charttype != 'rangeBar') {
      let obj = {
        series: series,
        chart: {
          height: 400,
          width: "95%",
          fontFamily: "Roboto, sans-serif",
          type: details.charttype,
          date: details.date ? details.date : [],
          // zoom: {
          //   enabled: false,
          // },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          curve: "smooth",
        },
        title: {
          text: details.seriesname,
          align: "left",
        },
        // subtitle: {
        //   text:
        //     "Duration : " +
        //     moment(details.startdate).format("DD-MMM-YYYY") +
        //     " - " +
        //     moment(details.enddate).format("DD-MMM-YYYY"),
        //   align: "left",
        // },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "category",
          title: {
            text: details.xaxislabel,
          },
          labels: {
            hideOverlappingLabels: false,
            maxHeight: 150,
          }
        },
        yaxis: {
          title: {
            text: details.yaxislabel,
          },
        },
        tooltip: {
          enabled: true,
          fixed: {
            enabled: true,
            position: "bottomLeft",
            offsetX: 0,
            offsetY: 0,
          },
          y: {
            formatter: function (val) {
              console.log(val);
              return val
            }
          },
          x: {
            formatter: function (val) {
              console.log(val);
              return val
            }
          }
        },
        legend: {
          showForSingleSeries: true,
          showForNullSeries: false,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: '12px',
          floating: false,
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              " - ",
              _.sum(opts.w.globals.series[opts.seriesIndex]),
            ];
          },
        },
        details: details,
      };
      if (details.reporttype == 'CMDB_SAVEDQUERY') {
        delete obj['xaxis']['type'];
        delete obj['chart']['date'];
        obj.xaxis['categories'] = details.categories;
        obj.xaxis['title'] = details.xaxislabel;
      }
      if (details.reporttype == 'SYNTHETICS' && isdateime) {
        obj['xaxis']['type'] = 'datetime';
        obj['xaxis']['labels']['datetimeUTC'] = false;
        obj['tooltip']['x']['show'] = true;
        obj['tooltip']['x']['format'] = 'dd-MMM-yyyy hh:mm:ss';
        obj['tooltip']['x']['formatter'] = function (val) {
          return moment(val).format("DD-MMM-YYYY hh:mm:ss")
        }
        obj['tooltip']['y']['formatter'] = function (val) {
          return val + ' ms'
        };
        obj['legend']['formatter'] = function (seriesName, opts) {
          return seriesName;
        }
      };
      console.log(obj)
      return obj;
    } else {
      let xaxiscount = 1200;
      series.map((o) => {
        xaxiscount = xaxiscount + o.data.length;
      });
      return {
        series: series,
        chart: {
          height: (details.totalprojects && (details.totalprojects < 5 && details.totalprojects != 0)) ? 300 : xaxiscount,
          width: '95%',
          type: "rangeBar",
          date: details.date ? details.date : [],
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: (details.totalprojects && (details.totalprojects < 5 && details.totalprojects != 0)) ? '20%' : '100%',
            columnWidth: '100%',
            rangeBarGroupRows: true,
          },
        },
        grid: {
          row: {
            opacity: 1
          },
          column: {
            opacity: 1
          },
        },
        title: {
          text: details.seriesname,
          align: "left",
        },
        subtitle: {
          text: 'Project limit is 50 for chart view.',
          style: {
            fontSize: '10px',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            color: '#9699a2'
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              cssClass: "apexcharts-xaxis-label",
            },
          }
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
        legend: {
          position: "bottom",
        },
        tooltip: {
          custom: function (opts) {
            const fromDay = new Date(opts.y1).toISOString();
            const toDay = new Date(opts.y2).toISOString();
            const day1 = fromDay.substring(0, 10);
            const timestamp1 = fromDay.substring(11, 19);
            const day2 = toDay.substring(0, 10);
            const timestamp2 = toDay.substring(11, 19);


            var data = opts.w.globals.initialSeries[opts.seriesIndex];
            let duration1: any = new Date(opts.w.config.series[opts.seriesIndex]['data'][opts.dataPointIndex].y[0]);
            let duration2: any = new Date(opts.w.config.series[opts.seriesIndex]['data'][opts.dataPointIndex].y[1]);

            // get total seconds between the times
            var delta = Math.abs(duration2 - duration1) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;
            var seconds = Math.floor(delta % 60);  // in theory the modulus is not required
            return (
              '<div class="apexcharts-tooltip-rangebar">' +
              '<div>' +
              data['data'][opts.dataPointIndex].x +
              "</div>" +
              '<div> <span class="category">' +
              (data.name ? data.name : "") + ' : ' +
              ' </span> <span class="value start-value">' +
              `${moment(day1).format('DD-MMM-YYYY')} ${timestamp1}` +
              '</span> <span class="separator">-</span> <span class="value end-value">' +
              `${moment(day2).format('DD-MMM-YYYY')} ${timestamp2}` +
              "</span></div>" +
              '<div> <span class="category">' + 'Duration : ' + (days > 0 ? days + ' Day,' : '') + hours + ' Hours,' + minutes + ' Minutes,' + seconds + ' Seconds'
              + "</span></div>" +
              "</div>"
            )
          }
        },
        details: details,
      };
    }

  }

  getResourceType(details) {
    this.kpiReportingService
      .getResourceTypes(details.tenantid)
      .subscribe((result) => {
        let response = {} as any;
        response = JSON.parse(result._body);
        if (response) {
          response = _.orderBy(response, ["resource"], ["asc"]);
          this.resourceTypes = response;
        } else {
          this.resourceTypes = [];
        }
      });
  }

  onClickFilters(type, data) {
    this.reporttype = type;
    this.reportdetails = data;
    if (!_.isEmpty(JSON.parse(data.filterby))) {
      let filters = JSON.parse(data.filterby);
      if (this.reporttype == 'CMDB' && filters.attribute && filters.attribute.length > 0) {
        this.reportdetails['selectedattributes'] = filters.attribute;
      }
      if (this.reporttype != 'CMDB' && filters) {
        this.reportdetails['selectedfilters'] = filters;
      }
    }
    this.openfilter = true;
  }
  applyFilters(e) {
    this.enablefilter = false;
    if (e.reporttype == 'CMDB') {
      this.reportdetails['selectedValues'] = e.filters;
      this.getCMDB(this.reportdetails);
    }
  }
  applyCMDBFilter(e) {
    this.reportdetails['selectedattributes'] = e.attribute;
    this.getCMDB(this.reportdetails);
    this.openfilter = false;
  }
  submitFilters(e) {
    this.reportdetails['selectedfilters'] = { ...e.value };
    if (e.type == AppConstant.KPI_REPORING.TICKETS) {
      this.getTickets(this.reportdetails);
    }
    if (e.type == AppConstant.KPI_REPORING.MONITORING) {
      this.getMonitoring(this.reportdetails);
    }
    if (e.type == AppConstant.KPI_REPORING.ASSET) {
      this.getAssets(this.reportdetails);
    }
    if (e.type == AppConstant.KPI_REPORING.SSL) {
      this.getSSLReport(this.reportdetails)
    }
  }

  onChangeView(tabindex, i, type, flag) {
    if (type == 'tickets') this.kpitickets[i].details.currentTab = tabindex;
    if (type == 'monitoring') this.kpimonitorings[i].details.currentTab = tabindex;
    if (type == 'assets') this.kpiassets[i].details.currentTab = tabindex;
    if (type == 'cmdb') {
      this.kpicmdb[i].details.currentTab = tabindex;
      if (flag == 'list') this.kpicmdb[i].details.istable = true;
      if (flag == 'chart') this.kpicmdb[i].details.istable = false;
      this.getCMDB(this.kpicmdb[i].details);
    }
    if (type == 'customcmdb') {
      this.kpicustomcmdb[i].details.currentTab = tabindex;
      if (flag == 'list') this.kpicustomcmdb[i].details.istable = true;
      if (flag == 'chart') this.kpicustomcmdb[i].details.istable = false;
      this.getCMDBSavedQuery(this.kpicustomcmdb[i].details, 10, 0, i);
    }
    if (type == 'synthetics') {
      this.kpisynthetics[i].details.currentTab = tabindex;
    }
    if (type == 'ssl') {
      this.kpissl[i].details.currentTab = tabindex;
    }
  }

  downloadCSV(hdr, summary, name, dtls?, type?) {
    if (name == 'CMDB' && type == 'rangeBar') {
      this.download = true;
      this.getCMDB(dtls)
    } else {
      let data: any = [];
      let csvheaders = _.map(hdr, function (o: any) { return o.header });
      let headers = _.map(hdr, function (o: any) { return (name == 'CMDB_SAVEDQUERY' || name == 'SYNTHETICS') ? o.field : o.value });
      _.map(summary, function (value) {
        let obj = [];
        headers.map((o) => {
          if (value[o] != undefined) {
            obj.push(value[o])
          } else {
            obj.push('')
          }
        })

        data.push(obj);
      })
      console.log('result', data);
      // var csv = Papa.unparse([[...csvheaders], ...data]);
      var csv = Papa.unparse(data);

      var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var csvURL = null;
      csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", `${name}-${moment().format('DD-MMM-YYYY')}.csv`);
      tempLink.click();
    }
  }
  onpagechange(e, d, reporttype) {
    this.pageIndex = e;
    if (reporttype == AppConstant.KPI_REPORING.CMDB) this.getCMDB(d, undefined, 50, (e - 1) * 50);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
