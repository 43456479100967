import { Component, OnInit,Output,EventEmitter, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { NzMessageService } from "ng-zorro-antd";
import { AppConstant } from "src/app/app.constant";
import { LocalStorageService } from "src/app/services/shared/local-storage.service";
import { TenantsService } from "src/app/services/tenants.service";
import { LoginService } from "./login.service";
import { Buffer } from "buffer";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.less"],
})
export class LoginComponent implements OnInit {
  @Output() notifyChanges: EventEmitter<any> = new EventEmitter();
  @Input() showForgetPassword = true;
  loading = false;
  loggingin: Boolean = false;
  // errMessage: String = '';
  // successMessage: String = '';
  showOTP: Boolean = false;
  userData: any;
  resetPassword: any = false;
  token: string = "";
  userParam: any = {};
  labelText: string = "Login";
  qrcode = "";
  loginForm: FormGroup;
  customerid;
  customerObj: any = {};
  constructor(
    private loginService: LoginService,
    private router: Router,
    private message: NzMessageService,
    private route: ActivatedRoute,
    private tenantsService: TenantsService,
    private localStorageService: LocalStorageService,
    private domsanitizer: DomSanitizer
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required]),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        ),
      ]),
      otp: new FormControl(""),
      newpassword: new FormControl(
        "",
        Validators.pattern(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        )
      ),
      comfirmPassword: new FormControl(
        "",
        Validators.pattern(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        )
      ),
      remember: new FormControl(),
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params["customerid"] !== undefined) {
        this.customerid = Number(params.customerid);
        this.getCustomerInfo();
      }
    });
    if (
      this.localStorageService.getItem(AppConstant.LOCALSTORAGE.ISAUTHENTICATED)
    ) {
      this.moveToScreen();
    }
    this.userParam = this.route.snapshot.queryParams;
    if (this.userParam.resetpassword) {
      this.labelText = "Reset Password";
      this.resetPassword = true;
    } else {
      this.resetPassword = false;
    }
  }
  getCustomerInfo() {
    this.loading = true;
    this.tenantsService
      .customerbyId(
        this.customerid,
        `download=${true}&` +
          "attrubutes=" +
          JSON.stringify(["customerid", "customername", "dashboardconfig"])
      )
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.customerObj = response.data.data;
          if (response.data.content != null) {
            var buffer = Buffer.from(response.data.content.data);
            const TYPED_ARRAY = new Uint8Array(buffer);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, "");
            let base64String = btoa(STRING_CHAR);
            this.customerObj.logo = this.domsanitizer.bypassSecurityTrustUrl(
              "data:image/jpg;base64, " + base64String
            );
          }
        }
        this.loading = false;
      });
  }
  logIn() {
    this.loggingin = true;
    let response = {} as any;
    localStorage.setItem(
      AppConstant.LOCALSTORAGE.SESSION_TYPE,
      this.loginForm.value.remember == null ? "ss" : "ls"
    );
    let reqObj = {
      ...this.loginForm.value,
      customerid: this.customerid,
    };
    this.loginService.signinUser(reqObj, "?mode=customer").subscribe(
      (result) => {
        response = JSON.parse(result._body);
        if (response.status) {
          this.notifyChanges.next(response.data);
          this.userData = response;
          if (response.data.twofactorauthyn == "Y") {
            this.showOTP = response.data.twofactorauthyn == "Y" ? true : false;
            this.labelText = "Two Factor Authentication";
            if (response.data.qrcode) this.qrcode = response.data.qrcode;
            this.loginForm
              .get("otp")
              .setValidators([
                Validators.pattern("^[0-9]*$"),
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(6),
              ]);
            this.loginForm.updateValueAndValidity();
            this.loggingin = false;
          } else {
            this.redirectToLogin();
          }
        } else {
          this.message.error(response.message);
          this.loggingin = false;
        }
      },
      (err) => {
        console.log("Error is => ");
        this.loggingin = false;
        console.log(err);
      }
    );
  }
  redirectToLogin() {
    let params = this.route.snapshot.queryParams;
    this.localStorageService.addItem(
      AppConstant.LOCALSTORAGE.TOKEN,
      this.userData.data.token
    );
    this.localStorageService.addItem(
      AppConstant.LOCALSTORAGE.USER,
      this.userData.data
    );
    this.localStorageService.addItem(
      AppConstant.LOCALSTORAGE.ISAUTHENTICATED,
      this.userData.status
    );
    let screens = [];
    this.loggingin = false;
    if (
      !_.isUndefined(this.userData.data.roles) &&
      !_.isEmpty(this.userData.data.roles.roleaccess)
    ) {
      screens = this.userData.data.roles.roleaccess;
      this.localStorageService.addItem(
        AppConstant.LOCALSTORAGE.SCREENS,
        screens
      );
    } else {
      this.localStorageService.addItem(AppConstant.LOCALSTORAGE.SCREENS, []);
    }
    if (!this.userData.data.lastlogin) {
      this.labelText = "Reset Password";
      this.resetPassword = true;
      // this.router.navigate([params['resetpassword']]);
    } else {
      if (params["redirect"]) {
        this.router.navigate([params["redirect"]]);
      } else {
        this.moveToScreen();
      }
    }
  }
  verifyOTP() {
    this.loggingin = true;
    let response = {} as any;
    let formdata = this.loginForm.value as any;
    formdata.userid = this.userData.data.userid;
    formdata.qrsecret = this.userData.data.qrsecret;
    this.loginService.verifyOTP(formdata).subscribe(
      (result) => {
        response = JSON.parse(result._body);
        if (response.status) {
          this.redirectToLogin();
          this.message.success(response.message);
        } else {
          this.message.error(response.message);
          this.loggingin = false;
        }
      },
      (err) => {
        console.log("Error is => ");
        this.loggingin = false;
        console.log(err);
      }
    );
  }
  resendOTP() {
    let response = {} as any;
    let formdata = {} as any;
    let userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    formdata.userid = userstoragedata.userid;
    this.loginService.resendOTP(formdata).subscribe(
      (result) => {
        response = JSON.parse(result._body);
        if (response.status) {
          this.message.success(response.message);
        }
      },
      (err) => {
        console.log("Error is => ");
        this.loggingin = false;
        console.log(err);
      }
    );
  }
  forgotPassword() {
    this.loggingin = true;
    let formdata = this.loginForm.value as any;
    let response = {} as any;
    if (formdata.email) {
      formdata.weburl = AppConstant.WEB_END_POINT;
      this.loginService.forgotPassword(formdata).subscribe(
        (result) => {
          response = JSON.parse(result._body);
          this.loggingin = false;
          if (response.status) {
            this.message.success(response.message);
          } else {
            this.message.error(response.message);
          }
        },
        (err) => {
          this.loggingin = false;
          console.log(err);
        }
      );
    } else {
      this.loggingin = false;
      this.message.error("Please enter Email ID");
    }
  }
  moveToScreen() {
    this.router.navigate(["/dashboard/" + this.customerid]);
  }
  resetUserPassword() {
    this.loggingin = true;
    let data = this.loginForm.value;
    let response = {} as any;

    const r = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );

    if (!data.newpassword || !data.comfirmPassword) {
      this.message.error("Please enter new password and confirm password");
    } else if (data.newpassword !== data.comfirmPassword) {
      this.message.error("New password and confirm password should be same");
    } else if (!r.test(data.newpassword)) {
      this.message.error(
        "Password must contain one lowercase, uppercase, digit and special character."
      );
    } else {
      let formData = data;
      if (this.userData) {
        formData.email = this.userData.data.email;
      } else {
        formData.id = this.userParam.resetpassword;
        formData.forgetpassword = true;
      }
      this.loginService.resetPassword(formData).subscribe(
        (result) => {
          this.loggingin = false;
          response = JSON.parse(result._body);
          if (response.status) {
            this.labelText = "Login";
            this.resetPassword = false;
            if (this.userData) {
              this.moveToScreen();
            } else {
              this.router.navigate(["login"]);
            }
            this.message.success(response.message);
          } else {
            this.message.error(response.message);
          }
        },
        (err) => {
          this.loggingin = false;
          console.log(err);
        }
      );
    }
    this.loggingin = false;
  }
}
