import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstant } from "../app.constant";
import { HttpHandlerService } from "./http-handler.service";
import { Http } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class PrometheusService {
  endpoint: string;
  serviceURL: any;
  constructor(private httpHandler: HttpHandlerService, private http: Http) {
    this.serviceURL = AppConstant.API_CONFIG.API_URL.BASE.PROMETHEUS;
  }
  getVMStatus(data): Observable<any> {
    return this.http.post(
      AppConstant.API_END_POINT + this.serviceURL.VMSTATUS,
      data
    );
  }
  getBreakUp(data): Observable<any> {
    return this.http.post(
      AppConstant.API_END_POINT + this.serviceURL.METRIC_DTL,
      data
    );
  }
  getVMuptime(data?, query?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.VMUPTIME;
    if (query) url += `?${query}`;

    return this.httpHandler.POST(url, data);
  }
  getcurrentVMuptime(data?, query?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.CURRVMUPTIME;
    if (query) url += `?${query}`;

    return this.httpHandler.POST(url, data);
  }
  getCalendarData(data?, query?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.CALENDAR;
    if (query) url += `?${query}`;

    return this.httpHandler.POST(url, data);
  }
  
  kpisummary(data?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.KPISUMMARY;

    return this.httpHandler.POST(url, data);
  }
  getcount(data?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.COUNT;

    return this.httpHandler.POST(url, data);
  }
  getdatewisecount(data?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.DATEWISECOUNT;

    return this.httpHandler.POST(url, data);
  }
  getAllList(data?): Observable<any> {
    let url = AppConstant.API_END_POINT + this.serviceURL.DATALIST;

    return this.httpHandler.POST(url, data);
  }
}
