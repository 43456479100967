import { Injectable } from "@angular/core";
import { AppConstant } from "../app.constant";
import { HttpHandlerService } from "../services/http-handler.service";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  endpoint: string;
  serviceURL: any;
  constructor(private httpHandler: HttpHandlerService) {
    this.endpoint = AppConstant.API_END_POINT;
  }
  public allCustomers(data, query?) {
    let url =
      this.endpoint + AppConstant.API_CONFIG.API_URL.TENANTS.CLIENT.FINDALL;
    if (query) {
      url = url + query;
    }
    return this.httpHandler.POST(url, data);
  }
  public allCustomerAccount(data, query?) {
    let url =
      this.endpoint +
      AppConstant.API_CONFIG.API_URL.TENANTS.CUSTOMER_ACCOUNT.FINDALL;
    if (query) {
      url = url + query;
    }
    return this.httpHandler.POST(url, data);
  }
  public allUsers(data) {
    return this.httpHandler.POST(
      this.endpoint + AppConstant.API_CONFIG.API_URL.TENANTS.USERS.FINDALL,
      data
    );
  }
  public allLookupValues(data) {
    return this.httpHandler.POST(
      this.endpoint + AppConstant.API_CONFIG.API_URL.BASE.LOOKUP.FINDALL,
      data
    );
  }

  public allInstances(data, query?) {
    let url =
      this.endpoint + AppConstant.API_CONFIG.API_URL.TENANTS.INSTANCE.FINDALL;
    if (query) {
      url = url + "?" + query;
    }
    return this.httpHandler.POST(url, data);
  }
  public allDashboardConfig(data, query?) {
    let url =
      this.endpoint + AppConstant.API_CONFIG.API_URL.TENANTS.CLIENT.DASHBOARD_CONFIG;
    if (query) {
      url = url + query;
    }
    return this.httpHandler.POST(url, data);
  }
  public commonGetCall(url, query?,options?:any){
    if(url){
      if (query) url = url + query;
      return this.httpHandler.GET(url,options);
    }
  }
  public verifyCustomer(options?:any) {
    let url =
      this.endpoint + AppConstant.API_CONFIG.API_URL.TENANTS.CLIENT.VERIFYCUSTOMER;
    return this.httpHandler.GET(url, options);
  }
  public verifyGrafanaAuth(url : any,options?:any) {
    
    return this.httpHandler.GET(url, options);
  }
}
