import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { AppConstant } from "src/app/app.constant";
import { IncidentService } from "../../services/incidents.service";
import { PrometheusService } from "../../services/prometheus.service";
import { DecimalPipe } from "@angular/common";
import { Subscription } from "rxjs";
@Component({
  selector: "app-customer-dashboard",
  styleUrls: ["./customer-dashboard.component.css"],
  templateUrl: "./customer-dashboard.component.html",
})
export class CustomerDashboardComponent implements OnInit {
  @Input() maintenanceList = [];
  @Input() customerObj = {} as any;
  @Input() kpireportObj;
  dataSet = [];
  overview = true;
  tab1 = true;
  tab2 = false;
  tab3 = false;
  customerid;
  userstoragedata = {} as any;
  incidentList: any[] = [];
  dashboardConfig: any[] = [];
  svgList = [];
  components = [];
  dinstancesAvgs = [];
  dinstancesList = [];
  groupedData = {} as any;
  incidentObj = {} as any;
  ntfList = [];
  ntfObj = {} as any;
  isLoading = true;
  serverStatus = {
    avg: 0,
    sum: 0,
    fill: { backgroundColor: "#A3E798" },
    title: "Loading...",
  } as any;
  public subscriptions = new Subscription();
  constructor(
    private router: Router,
    private incidentService: IncidentService,
    private prometheusService: PrometheusService
  ) { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerObj.currentValue) {
      this.customerid = Number(this.customerObj.customerid);
      this.isLoading = true;
      this.getInstanceRefs();
      this.getAllIncidents();
    }
  }
  openMetrics() {
    this.router.navigate(["dashboard/metrics/" + this.customerid]);
  }
  getInstanceRefs() {
    try {
      this.dinstancesAvgs = [];
      this.dinstancesList = [];
      let days = 90;
      let data = {
        customer: this.customerid,
        customerid: this.customerid,
        tenantid: this.customerObj.tenantid,
        start:
          moment().subtract(days, "days").format("YYYY-MM-DDT") +
          "00:00:00.000Z",
        end:
          moment().format("YYYY-MM-DDT") +
          moment().format("HH:mm:ss.SSS") +
          "Z",
      };
      // if (this.customerid == AppConstant.MARS_CUSTOMERID) {
      //   data.start =
      //     moment("2022-10-22").format("YYYY-MM-DDT") + "00:00:00.000Z";
      // }
      this.subscriptions = this.prometheusService
        .getVMuptime(data, `ishome=${true}`)
        .subscribe((d) => {
          let response = JSON.parse(d._body);
          this.dinstancesList = response.data.dinstancesList;
          this.dashboardConfig = _.clone(response.data.dinstancesAvgs);
          this.dinstancesAvgs = _.clone(response.data.dinstancesAvgs);
          this.isLoading = false;
          let instances = "";
          _.each(this.dinstancesAvgs, (itm) => {
            instances = instances + "," + (itm.instances);
            return itm;
          });
          this.getCurrentData(instances);
        });
    } catch (E) {
      console.log(E);
    }
  }
  getCurrentData(instances) {
    try {
      let data = {
        customer: this.customerid,
        customerid: this.customerid,
        tenantid: this.customerObj.tenantid,
        start:
          moment().subtract(0, "days").format("YYYY-MM-DDT") + "00:00:00.000Z",
        end: moment().format("YYYY-MM-DDT23:59:59.SSS") + "Z",
        instances: _.replace(instances, /,/g, "|"),
      };
      this.subscriptions = this.prometheusService
        .getcurrentVMuptime(data, `ishome=${true}`)
        .subscribe((d) => {
          let response = JSON.parse(d._body);
          this.isLoading = false;
          _.map(this.dashboardConfig, (hdr, index) => {
            let obj = hdr.svgList[hdr.svgList.length - 1];
            obj.dategrp = [];
            if (hdr.instances) {
              let instances = hdr.instances.split(",");
              obj.dategrp = response.data.filter((d: any) => {
                let fDate = moment(d.date).format("YYYY-MM-DD");
                if (
                  _.includes(instances, d.instancerefid) &&
                  moment(fDate, "YYYY-MM-DD").isSame(
                    moment(obj.dDate, "YYYY-MM-DD")
                  )
                ) {
                  obj.sum = obj.sum + d.uptimepercent;
                  return d;
                }
              });
            }
            obj.sum = obj.sum ? obj.sum : 0;
            obj.avg = obj.dategrp.length > 0 ? obj.sum / obj.dategrp.length : 0;
            if (obj.avg >= hdr.avg) {
              obj.fill = "#A3E798";
              obj.style = { "background-color": "#A3E798" };
              obj.title =
                obj.avg.toFixed(2) + "%,No downtime recorded on " + obj.date;
            } else if (obj.avg >= 50 && obj.avg < hdr.avg) {
              obj.fill = "#ffa64d";
              obj.style = { "background-color": "#ffa64d" };
              obj.title =
                obj.avg.toFixed(2) + "%,Downtime recorded on " + obj.date;
            } else if (obj.avg > 0 && obj.avg < 50) {
              obj.fill = "#FF0000";
              obj.style = { "background-color": "#FF0000" };
              obj.title =
                obj.avg.toFixed(2) + "%,Servers are not stable " + obj.date;
            }
            hdr.overallavg =
              Number(obj.avg).toFixed(2) + Number(hdr.overallavg);
            hdr.avg3 = hdr.avg3 + obj.avg;
            if (obj.avg > 0) {
              hdr.len3 = hdr.len3 + 1;
            }
            hdr.overallavgprcnt = 0;
            this.dashboardConfig[index] = hdr;
            this.dashboardConfig[index].svgList[hdr.svgList.length - 1] = obj;
            this.overAvgCalc(this.dashboardConfig[index], index);
          });
        });
    } catch (E) {
      console.log(E);
    }
  }
  getAllIncidents() {
    let reqObj = {
      customerid: this.customerid,
      status: AppConstant.STATUS.ACTIVE,
      publishyn: "Y",
    } as any;
    let days = 30;
    let enddate = moment().format("YYYY-MM-DD") + " 23:59:59";
    let startdate =
      moment().subtract(days, "days").format("YYYY-MM-DD") + " 00:00:00";
    reqObj["startdate"] = startdate;
    reqObj["enddate"] = enddate;
    this.incidentService.all(reqObj).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.incidentList = _.orderBy(
          response.data.rows,
          ["incidentdate"],
          ["desc"]
        );
        this.incidentObj = _.groupBy(this.incidentList, "incidentdate");
      }
    });
  }
  overAvgCalc(t, i) {
    // t.overallavgprcnt = t.overallavg ? t.overallavg / t.len : 100;
    let overallsize = t.len1 + t.len2 + t.len3;
    t.overallavgprcnt = (t.avg1 + t.avg2 + t.avg3) / overallsize;
    t.thirtyavgprcnt = t.avg3 > 0 ? t.avg3 / t.len3 : 0;
    this.dashboardConfig[i] = t;
    this.serverStatus.sum = _.sumBy(this.dashboardConfig, (d) => {
      return d.overallavgprcnt;
    });
    this.serverStatus.avg = this.serverStatus.sum
      ? this.serverStatus.sum
      : 0 / this.dashboardConfig.length;
    this.serverStatus.title = "No Metrics Found";
    if (_.includes(this.dashboardConfig, { fill: "#FF0000" })) {
      this.serverStatus.fill = { backgroundColor: "#FF0000" };
      this.serverStatus.title = "Servers at critical stage";
    } else if (_.includes(this.dashboardConfig, { fill: "#ffa64d" })) {
      this.serverStatus.fill = { backgroundColor: "#ffa64d" };
      this.serverStatus.title = "Servers running with average uptime";
    } else {
      this.serverStatus.fill = { backgroundColor: "#A3E798" };
      this.serverStatus.title = "All servers running Normal";
    }
  }
  dateFormat(date) {
    return moment(date * 1000).format("DD-MM-YYYY");
  }
  updateExpandView(idx?, sidx?) {
    let selectedData: any = this.dashboardConfig[idx].svgList[sidx];
    let dataGrp = [];
    if (selectedData.dategrp.length > 0) {
      dataGrp = selectedData.dategrp;
      this.formatData(idx, sidx, dataGrp, selectedData);
    } else {
      let data = {
        customer: this.customerid,
        customerid: this.customerid,
        tenantid: this.customerObj.tenantid,
        date: selectedData.dDate,
        instances: this.dashboardConfig[idx].instances,
      };
      this.prometheusService.getBreakUp(data).subscribe((d) => {
        let response = JSON.parse(d._body);
        // this.isLoading = false;
        _.map(response.data, (itm) => {
          let exist = _.find(dataGrp, { instancerefid: itm.instancerefid });
          if (!exist) {
            dataGrp.push(itm);
          }
        });
        this.formatData(idx, sidx, dataGrp, selectedData);
      });
    }
  }
  formatData(idx, sidx, dataGrp, selectedData) {
    _.map(dataGrp, (itm) => {
      let instance = _.find(this.dinstancesList, {
        instancerefid: itm.instancerefid,
      });
      itm.instancename = instance.displayname;
      itm.displayorder = instance.displayorder;
      itm.actualsla = instance.uptime;
      itm.avg = itm.uptimepercent;
      itm.downtime = 1440 - (itm.uptimepercent / 100) * 1440;
      itm.downtimeyn = instance.downtimeyn == "Y" ? true : false;
      itm.downtime = _.round(itm.downtime);
      return itm;
    });
    this.dashboardConfig[idx].isExpand = true;
    this.dashboardConfig[idx].svgList[sidx].dategrp = _.sortBy(dataGrp, [
      "displayorder",
    ]);
    selectedData = this.dashboardConfig[idx].svgList[sidx];
    this.dashboardConfig[idx].selectedData = selectedData;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
