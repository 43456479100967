import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationGuard } from "./authentication.guard";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { ResultErrorComponent } from "./components/permissiondenied.component";

const routes: Routes = [
  {
    path: "login/:customerid",
    component: LoginComponent,
    canActivate: [AuthenticationGuard],
    data: {
      title: "Customer App - Login",
    },
  },

  {
    path: "dashboard/:customerid",
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    data: {
      title: "Customer Dashboard",
    },
  },
  {
    path: "accessdenied",
    component: ResultErrorComponent,
    data: {
      title: "Customer Dashboard",
    },
  },
  {
    path: "",
    component: ResultErrorComponent,
    data: {
      title: "Customer Dashboard",
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ], // .../#/getcall
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
