import { Inject, Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { AppConstant } from "./app.constant";
import { AuthenticationService } from "./authentication.service";
import { LocalStorageService } from "./services/shared/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard implements CanActivate {
  userstoragedata = {} as any;
  constructor(
    private authorizationService: AuthenticationService,
    private router: Router,
    private storageService: LocalStorageService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.userstoragedata = this.storageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    return this.checkPermission(Number(route.params.customerid), state);
  }
  private checkAuthentication(customerid: number) {
    return this.authorizationService
      .checkAuthStatus(customerid)
      .then((result: boolean) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        return false;
      });
  }
  private checkUserAccess(email: string, customerid: number) {
    return this.authorizationService
      .checkAuth(email, customerid)
      .then((result: boolean) => {
        if (result) {
          return true;
        } else {
          this.router.navigate["/accessdenied"];
          return false;
        }
      })
      .catch((e) => {
        this.router.navigate["/accessdenied"];
        return false;
      });
  }
  private checkPermission(customerid: number, state) {
    // check auth enabled or not
    return this.checkAuthentication(customerid).then((result) => {
      if (result) {
        if (
          !_.isEmpty(this.userstoragedata) &&
          !_.isNull(this.userstoragedata)
        ) {
          return this.checkUserAccess(
            this.userstoragedata.email,
            Number(customerid)
          );
        } else {
          return this.moveTo(state, "login", customerid);
        }
      } else {
        return this.moveTo(state, "dashboard", customerid);
      }
    });
  }
  moveTo(state, flag, customerid) {
    if (state.url.match(/login/) && flag == "login") {
      return true;
    } else if (state.url.match(/dashboard/) && flag == "dashboard") {
      return true;
    } else if (flag == "login") {
      this.router.navigateByUrl("/login/" + customerid);
      return false;
    } else if (flag == "dashboard") {
      this.router.navigateByUrl("/dashboard/" + customerid);
      return false;
    }
  }
}
