import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { KPIReportingService } from "../../services/kpi-reporting.service";
import * as _ from "lodash";
import * as moment from "moment";
import { Buffer } from "buffer";
import downloadService from "../../services/shared/download.service";
import { CommonService } from "../../services/common.service";
import { AssetRecordService } from "../../services/assetrecords.service";
import { AppConstant } from "src/app/app.constant";
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Subscription } from "rxjs";
const DEFAULT_DURATION = 300;
@Component({
  selector: "custom-report",
  templateUrl: "./custom-report.component.html",
  styleUrls: ["./custom-report.component.css"],
  animations: [
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate(DEFAULT_DURATION + "ms ease-in")),
      transition("true => false", animate(DEFAULT_DURATION + "ms ease-out")),
    ]),
  ],
})
export class CustomReportComponent implements OnInit {
  @Input() kpireportObj: any = [];
  @Input() customerObj: any = {};
  reports = [];
  customerid;
  xaxis = [];
  reporttype;
  enablefilter = false;
  filterableValues = [];
  reportdetails = {};
  openfilter = false;
  currentTab = 0;
  customReportSummary: any = [];
  customReportSummaryTitle: any = [];
  customReportHeader: any = AppConstant.KPI.CUSTOMREPORT_HEADER;
  startMonth: any = moment().subtract(3, "months").toDate();
  endMonth: any = moment().toDate();
  startWeek: any = this.startMonth;
  endWeek: any = this.endMonth;
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    const today = new Date();
    let difference = moment(today).diff(moment(current), "months");
    return difference > 0 ? false : true;
  };
  thresoldStatus_range: any;
  countryList: any[] = [];
  defaultCountry: any = {
    fieldvalue: "All",
  };
  selectedCountry: any = this.defaultCountry;
  periodList: any[] = ["Daily", "Weekly", "Monthly"];
  selectedPeriod: string = "Monthly";
  dateRange: any[] = [
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ];
  ReportDetailsVisible: boolean = false;
  ReportDetailsList: any[] = [];
  selectedReportRow: any = {};
  filtersByTask = [];
  currentFilter: any;
  filterGroup: any[] = [
    {
      title: "Task",
      active: false,
      filterableValues: [],
      values: [],
    },
  ];
  filterSearchModel: any = "";
  Listpagesize = 500;
  detailListpagesize = 50;
  dataloading = false;
  taskListLoaded = false;
  public subscriptions = new Subscription();
  constructor(
    private kpiReportingService: KPIReportingService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private assetRecordService: AssetRecordService
  ) {
    this.route.params.subscribe((params) => {
      if (params["customerid"] !== undefined) {
        this.customerid = Number(params.customerid);
      }
    });
    // this.getAllTasks();
    this.getthresoldStatusRange();
  }

  ngOnInit() {
    this.getCustomReport();
    this.getCountryList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kpireportObj && changes.kpireportObj.currentValue) {
      this.kpireportObj = changes.kpireportObj.currentValue;
    }
    if (changes.customerObj && changes.customerObj.currentValue) {
      this.customerObj = changes.customerObj.currentValue;
    }
  }
  getCountryList() {
    this.assetRecordService
      .getAllDetail(
        {
          crn: "crn:esko:tetrapack",
          status: AppConstant.STATUS.ACTIVE,
          fieldkey: "crn:esko:tetrapack/fk:country",
        },
        "groupby=fieldvalue"
      )
      .subscribe((result) => {
        let response = {} as any;
        response = JSON.parse(result._body);
        if (response.status) {
          this.countryList = response.data || [];
          this.countryList.unshift(this.defaultCountry);
        } else {
          this.countryList.unshift(this.defaultCountry);
        }
      });
  }
  getAllTasks() {
    // this.dataloading = true;
    this.taskListLoaded = true;
    this.dataloading = true;
    let selectedTasks = [];
    let alltasks = [];
    try {
      const months = [];
      let dateStart = _.clone(moment(this.dateRange[0]));
      var dateEnd = _.clone(moment(this.dateRange[1]));
      const flag = dateStart.clone();
      while (dateStart.isBefore(dateEnd, "day")) {
        months.push(dateStart.format("DD-MMM-YYYY"));
        dateStart.add(1, "days");
      }
      this.kpiReportingService
        .getCustomReportTaskList(
          {
            tenantid: this.customerObj.tenantid,
            crn: "crn:esko:tetrapack",
            startdate: moment(this.dateRange[0]).format("YYYY-MM-DD"),
            enddate: moment(this.dateRange[1]).format("YYYY-MM-DD"),
            // daterange: months,
            views_type: "tetrapack",
            country:
              this.selectedCountry.fieldvalue != "All"
                ? this.selectedCountry.fieldvalue
                : "",
            task: selectedTasks.length > 0 ? selectedTasks : alltasks,
          },
          "?period=" + this.selectedPeriod
        )
        .subscribe((result) => {
          this.dataloading = false;
          let response = {} as any;
          response = JSON.parse(result._body);
          if (response.status) {
            this.filterGroup[0].values = response.data.map((el) => {
              return {
                title: el.title,
                value: el.title,
              };
            });
            this.filterGroup[0].filterableValues = _.clone(
              this.filterGroup[0].values
            );
            // response = _.orderBy(response, ["resource"], ["asc"]);
          } else {
            this.filterGroup[0].filterableValues = [];
          }
        });
    } catch (error) {
      this.dataloading = false;
    }
    // this.commonService
    //   .allLookupValues({
    //     status: AppConstant.STATUS.ACTIVE,
    //     lookupkey: AppConstant.LOOKUPKEY.CUSTOM_REPORT_TTC,
    //   })
    //   .subscribe((result) => {
    //     let response = JSON.parse(result._body);
    //     if (response.status) {
    //       _.each(response.data, () => {});
    //       this.filterGroup[0].values = response.data.map((el) => {
    //         return {
    //           title: el.keyname,
    //           value: el.keyvalue,
    //         };
    //       });
    //       this.filterGroup[0].filterableValues = _.clone(
    //         this.filterGroup[0].values
    //       );
    //     } else {
    //       this.filterGroup[0].filterableValues = [];
    //     }
    //   });
  }
  getFieldValues(i) {
    // CLient Side search
    if (this.filterSearchModel == "") {
      this.filterGroup[0].filterableValues = _.clone(
        this.filterGroup[0].values
      );
    }
    this.filterGroup[i].originalData = this.filterGroup[i].filterableValues;
    this.filterGroup[i].filterableValues = [];
    this.filterGroup[i].originalData.map((item) => {
      const element = item["title"];
      const regxExp = new RegExp("\\b" + this.filterSearchModel, "gi");
      if (regxExp.test(element)) {
        if (!_.some(this.filterGroup[i].filterableValues, item)) {
          this.filterGroup[i].filterableValues.push(item);
        }
      }
    });
  }
  applyfilterby(event) {
    this.getCustomReport();
  }
  getthresoldStatusRange() {
    let resdata = [
      {
        keyname: '{"from":1,"to":30}',
        keyvalue: "#fab1bc",
      },
      {
        keyname: '{"from":30,"to":60}',
        keyvalue: "#fdd6a5",
      },
      {
        keyname: '{"from":60,"to":100}',
        keyvalue: "#a6f7dc",
      },
    ];
    this.thresoldStatus_range = _.map(resdata, (r) => {
      r.keyname = JSON.parse(r.keyname);
      return r;
    });
  }
  // this.commonService
  //   .allLookupValues({
  //     status: AppConstant.STATUS.ACTIVE,
  //     lookupkey: AppConstant.LOOKUPKEY.CSTM_RPT_RAG,
  //   })
  //   .subscribe((result) => {
  //     let response = {} as any;
  //     response = JSON.parse(result._body);
  //     if (response.status) {
  //       let resdata = response.data || [];
  //       if (resdata.length > 0) {
  //       resdata = [{"lookupid":928,"tenantid":7,"lookupkey":"CSTM_RPT_RAG","keyname":"{\"from\":1,\"to\":30}","keydesc":null,"keyvalue":"#fab1bc","datatype":"string","defaultvalue":null,"status":"Active","createdby":"Admin","createddt":"2022-11-03T05:06:32.000Z","lastupdatedby":"Admin","lastupdateddt":"2022-11-03T05:06:32.000Z"},{"lookupid":929,"tenantid":7,"lookupkey":"CSTM_RPT_RAG","keyname":"{\"from\":30,\"to\":60}","keydesc":null,"keyvalue":"#fdd6a5","datatype":"string","defaultvalue":null,"status":"Active","createdby":"Admin","createddt":"2022-11-03T05:06:33.000Z","lastupdatedby":"Admin","lastupdateddt":"2022-11-03T05:06:33.000Z"},{"lookupid":930,"tenantid":7,"lookupkey":"CSTM_RPT_RAG","keyname":"{\"from\":60,\"to\":100}","keydesc":null,"keyvalue":"#a6f7dc","datatype":"string","defaultvalue":null,"status":"Active","createdby":"Admin","createddt":"2022-11-03T05:06:33.000Z","lastupdatedby":"Admin","lastupdateddt":"2022-11-03T05:06:33.000Z"}]
  //         this.thresoldStatus_range = _.map(resdata, (r) => {
  //           r.keyname = JSON.parse(r.keyname);
  //           return r;
  //         });
  //       }
  //     }
  //   });
  // }
  getThresoldStyle(col, rowdata) {
    if (col == "percentage") {
      let rowValue = Number(rowdata[col]);
      if (typeof rowValue == "number" && this.thresoldStatus_range) {
        if (this.thresoldStatus_range.length > 0) {
          let rangeValue: any = _.find(this.thresoldStatus_range, (rge) => {
            if (typeof rge.keyname == "object") {
              if (rowValue >= rge.keyname.from && rowValue <= rge.keyname.to) {
                return true;
              }
            }
            return false;
          });
          if (rangeValue) {
            return rangeValue.keyvalue;
          }
        }
      }
    } else if (col == "jnot") {
      let rowValue = Number(rowdata[col]);
      return rowValue > 0 ? "#fab1bc" : "#fff";
    }
    return "#fff";
  }
  getCustomReport(download: boolean = false) {
    this.dataloading = true;
    let selectedTasks = [];
    let alltasks = [];
    try {
      _.filter(this.filterGroup[0].filterableValues, (itm) => {
        alltasks.push(itm.title);
        if (itm.selected) {
          selectedTasks.push(itm.title);
          return itm;
        }
      });
      if (!download) {
        this.customReportSummaryTitle = [];
        this.customReportSummary = [];
      }
      const months = [];
      let dateStart = _.clone(moment(this.dateRange[0]));
      var dateEnd = _.clone(moment(this.dateRange[1]));
      const flag = dateStart.clone();
      while (dateStart.isBefore(dateEnd, "day")) {
        months.push(dateStart.format("DD-MMM-YYYY"));
        dateStart.add(1, "days");
      }
      this.subscriptions = this.kpiReportingService
        .getCustomReports(
          {
            tenantid: this.customerObj.tenantid,
            crn: "crn:esko:tetrapack",
            startdate: moment(this.dateRange[0]).format("YYYY-MM-DD"),
            enddate: moment(this.dateRange[1]).format("YYYY-MM-DD"),
            // daterange: months,
            views_type: "tetrapack",
            country:
              this.selectedCountry.fieldvalue != "All"
                ? this.selectedCountry.fieldvalue
                : "",
            task: selectedTasks.length > 0 ? selectedTasks : alltasks,
          },
          "?period=" +
            this.selectedPeriod +
            (download ? "&download=" + download : "")
        )
        .subscribe((result) => {
          this.dataloading = false;
          let response = {} as any;
          response = JSON.parse(result._body);
          if (download) {
            var buffer = Buffer.from(response.data.content.data);
            downloadService(
              buffer,
              `Business-KPI-Report-${moment().format("DD-MMM-YYYY")}.csv`
            );

            // var csvData = new Blob([buffer], { type: "text/csv;charset=utf-8;" });
            // var csvURL = null;
            // csvURL = window.URL.createObjectURL(csvData);
            // const tempLink = document.createElement("a");
            // tempLink.href = csvURL;
            // tempLink.setAttribute("download", `test-${moment().format('DD-MMM-YYYY')}.csv`);
            // tempLink.click();
          } else {
            if (response.status) {
              this.customReportSummary = response.data.summary;
              this.customReportSummaryTitle = response.data.summarytitle;
              this.Listpagesize = this.customReportSummaryTitle.length;
            }
          }
        });
    } catch (error) {
      this.dataloading = false;
    }
  }
  title = {};
  getCustomReportDetails(rowData, showPanel, filter) {
    this.ReportDetailsList = [];
    this.dataloading = true;
    let dateStart = _.clone(moment(this.dateRange[0]).format("YYYY-MM-DD"));
    var dateEnd = _.clone(moment(this.dateRange[1]).format("YYYY-MM-DD"));
    try {
      let selectedPeriod = moment(rowData.period, "DD-MMM-YYYY");
      let ds = _.clone(dateStart);
      let de = _.clone(dateEnd);
      if (this.selectedPeriod == "Monthly") {
        // let l_dateStart = _.clone(selectedPeriod)
        // .startOf("month");
        let l_dateStart = _.clone(selectedPeriod)
          .startOf("month")
          .format("YYYY-MM-DD");
        let l_dateEnd = _.clone(selectedPeriod)
          .endOf("month")
          .format("YYYY-MM-DD");
        if (
          moment(l_dateStart).isBetween(ds, de) ||
          moment(l_dateStart).isSameOrAfter(ds)
        ) {
          dateStart = l_dateStart;
        }
        if (
          moment(l_dateEnd).isBetween(ds, de) ||
          moment(l_dateEnd).isSameOrBefore(de)
        ) {
          dateEnd = l_dateEnd;
        }
      } else if (this.selectedPeriod == "Weekly") {
        let sdate = moment()
          .year(selectedPeriod.year())
          .week(rowData.weekofy)
          .day(0);
        let l_dateStart = _.clone(sdate).format("YYYY-MM-DD");
        let l_dateEnd = _.clone(sdate).add(6, "days").format("YYYY-MM-DD");
        if (
          moment(l_dateStart).isBetween(ds, de) ||
          moment(l_dateStart).isSameOrAfter(ds)
        ) {
          dateStart = l_dateStart;
        }
        if (
          moment(l_dateEnd).isBetween(ds, de) ||
          moment(l_dateEnd).isSameOrBefore(de)
        ) {
          dateEnd = l_dateEnd;
        }
      } else if (this.selectedPeriod == "Daily") {
        dateStart = _.clone(selectedPeriod).format("YYYY-MM-DD");
        dateEnd = dateStart;
      }
      // dateStart = _.clone(moment(this.dateRange[0]));
      // dateEnd = _.clone(moment(this.dateRange[1]));
      this.kpiReportingService
        .getCustomReportDetails(
          {
            tenantid: this.customerObj.tenantid,
            crn: "crn:esko:tetrapack",
            startdate: dateStart,
            enddate: dateEnd,
            filter: filter,
            country:
              this.selectedCountry.fieldvalue != "All"
                ? this.selectedCountry.fieldvalue
                : "",
            // daterange: dates,
            title: rowData.title,
            // task1: rowData.task1,
            // task2: rowData.task2,
          },
          ""
        )
        .subscribe((result) => {
          let response = {} as any;
          this.dataloading = false;
          response = JSON.parse(result._body);
          if (response.status) {
            this.ReportDetailsList = response.data;
            //if (filter == "jnot" ||  filter == "jot") {
            this.ReportDetailsList = _.filter(
              this.ReportDetailsList,
              (details) => {
                details.approved_ttc = parseInt(details.approved_ttc) || 0;
                details.timetocomplete = parseInt(details.timetocomplete) || 0;
                details.completed = moment(details.completed)
                  .utc()
                  .format("YYYY-MM-DD HH:MM:ss");
                details.bstarted = moment(details.bstarted)
                  .utc()
                  .format("YYYY-MM-DD HH:MM:ss");
                return details;
              }
            );
            this.detailListpagesize = this.ReportDetailsList.length;
            //}
          }
          if (showPanel) {
            this.ReportDetailsVisible = true;
            this.title = rowData.title;
          }
        });
    } catch (error) {
      console.log(error);
      this.dataloading = false;
    }
  }
  changeCountry(event) {
    this.getAllTasks();
    this.getCustomReport();
  }
  changePeriod(event) {
    this.getCustomReport();
  }
  hideDetailsPanel() {
    this.ReportDetailsVisible = false;
    this.ReportDetailsList = [];
  }
  changeChartType(type, i, event) {}
  getTickets(details, date?) {}
  onChangeView(tabindex, i, type) {}
  onClickFilters(type?, data?) {
    if (!this.taskListLoaded) {
      this.getAllTasks();
    }
    this.openfilter = true;
  }
  downloadCSV(hdr, summary, name) {}
  onWeekChange(event, mode) {
    const today = new Date();
    if (mode == "start") {
      let difference = moment(event).diff(moment(this.endWeek), "weeks");
      if (difference > 0) {
        this.endWeek = moment(event).add(1, "weeks").toDate();
      }
    } else if (mode == "end") {
      let difference = moment(event).diff(moment(this.startWeek), "weeks");
      if (difference < 0) {
        this.startWeek = moment(event).subtract(1, "months").toDate();
      }
    }
    this.dateRange = [this.startWeek, this.endWeek];
    // this.getCustomReport();
  }
  onMonthChange(event, mode) {
    const today = new Date();
    if (mode == "start") {
      let difference = moment(event).diff(moment(this.endMonth), "months");
      if (difference > 0) {
        this.endMonth = moment(event).add(1, "months").toDate();
      }
    } else if (mode == "end") {
      let difference = moment(event).diff(moment(this.startMonth), "months");
      if (difference < 0) {
        this.startMonth = moment(event).subtract(1, "months").toDate();
      }
    }
    this.dateRange = [this.startMonth, this.endMonth];
    this.startWeek = this.startMonth;
    this.endWeek = this.endMonth;
    this.getCustomReport();
  }
  onDateRangeChange(event: Date[]) {
    if (event.length == 2) {
      this.getCustomReport();
    }
  }
  getFormat(data) {
    return JSON.stringify(data);
  }
  expandRow(row, filter) {
    this.selectedReportRow = row;
    if (row) {
      row.expand = !row.expand;
      this.getCustomReportDetails(row, true, filter);
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
