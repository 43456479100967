import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AppConstant } from "src/app/app.constant";
import { TenantsService } from "../../../services/tenants.service";
import { TagValueService } from "../../../services/tagvalue.service";
import { TagService } from "../../../services/tags.service";
import { CommonService } from "../../../services/common.service";
import { AWSService } from "../../../services/aws-service";
import * as _ from "lodash";
import { AssetRecordService } from "../../../services/assetrecords.service";
import {KPIReportingService} from "../../../services/kpi-reporting.service";
import { ActivatedRoute } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: "app-additional-filters",
  templateUrl: "./additional-filters.component.html",
  styleUrls: ["./additional-filters.component.css"]
})
export class AdditionalFiltersComponent implements OnInit {
  @Input() tenantid;
  @Input() reportdetails = {};
  @Input() groups: any = [];

  @Output() applyTicketFilter: EventEmitter<any> = new EventEmitter();
  @Output() applyAssetFilter: EventEmitter<any> = new EventEmitter();
  @Output() applyMonitoringFilter: EventEmitter<any> = new EventEmitter();
  @Output() applyCMDBFilter: EventEmitter<any> = new EventEmitter();
  @Output() applySSLFilter: EventEmitter<any> = new EventEmitter();

  @Output() submitFilters: EventEmitter<any> = new EventEmitter();

  selectedFilters = {};
  // groups: any = [];
  currentFilter: any;
  filterableValues: any[];
  filterMenuVisible: boolean = false;
  dataloading: boolean = false;
  filters = {};
  userstoragedata: any;
  filterSearchModel: any = "";
  attributemenu: boolean = false;
  attributeList = [{
    type: null,
    formula: null,
    value: "",
  }] as any;
  attributes = [
    {
      fieldname: "",
      formula: "",
      value: "",
    },
  ];
  operations = [
    {
      label: "Less than",
      value: "<",
      datatypes: ["Integer", "Float", "Date", "DateTime"],
    },
    {
      label: "Greater than",
      value: ">",
      datatypes: ["Integer", "Float", "Date", "DateTime"],
    },
    {
      label: "Equal to",
      value: "=",
      datatypes: [
        // "AUTOGEN",
        "Integer",
        "Float",
        "Date",
        "DateTime",
      ]
    },
    {
      label: "in",
      value: "in",
      datatypes: [
        "AUTOGEN",
        "Text",
        "Textarea",
        "Select",
        "Boolean",
        "STATUS",
        "REFERENCE",
      ],
    },
    { label: "Between", value: "BETWEEN", datatypes: ["Date", "DateTime"] },
  ];
  customerid;
  crn;
  constructor(
    private tenantsService: TenantsService,
    private tagValueService: TagValueService,
    private tagService: TagService,
    private commonService: CommonService,
    private awsService: AWSService,
    private assetRecordService: AssetRecordService,
    private kpiReportingService: KPIReportingService,
    private route: ActivatedRoute,
    private message: NzMessageService
  ) {
    this.route.params.subscribe((params) => {
      if (params["customerid"] !== undefined) {
        this.customerid = Number(params.customerid);
      }
    });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groups && changes.groups.currentValue) {
      this.groups = changes.groups.currentValue;
    }

    if (
      changes.reportdetails &&
      !_.isEmpty(changes.reportdetails.currentValue)
    ) {
      let filters = JSON.parse(changes.reportdetails.currentValue.filterby);
      if (changes.reportdetails.currentValue.reporttype == 'CMDB') {
        this.filterMenuVisible = true;
        this.attributemenu = true;
        if (filters && filters.crn) {
          this.crn = filters.crn[0];
          this.getAttributes(this.crn);
        }
      } else {
        let self = this;
        if (filters && this.reportdetails['selectedfilters']) {
          _.map(this.reportdetails['selectedfilters'], function (value, key) {
            let grroup = _.findIndex(self.groups, { value: key });
            if (grroup != -1) {
              self.groups[grroup].selectedvalues = value;
            }
          });
          this.groups = [...this.groups];
        }
        this.groups = _.map(this.groups, function (o) {
          o.active = false;
          return o;
        })
        this.filterMenuVisible = false;
        this.attributemenu = false;
      }
    }
  }

  getFilterValue(group, i?) {
    if (i != undefined) {
      this.groups = _.map(this.groups, function (o, idx) {
        o.active = (idx == i) ? true : false;
        return o;
      })
    }
    this.currentFilter = group;
    this.filterableValues = [];
    this.attributemenu = false;
    switch (group.value) {
      case "customerid":
        this.getAllCustomers();
        break;
      case "_customer":
        this.getAllCustomers();
        break;
      case "tagid":
        this.getAllTags();
        break;
      case "tagvalue":
        let tagid = _.find(this.groups, function (e) {
          return e.type == group.type && e.value == "tagid";
        });
        this.getTagValues(tagid);
        break;
      case "referencetype":
        this.filterableValues = [
          {
            title: "System",
            value: "System",
          },
          {
            title: "Security",
            value: "Security",
          },
        ];
        break;
      case "severity":
        if (group.type == AppConstant.KPI_REPORING.TICKETS) {
          this.getLookupValues(AppConstant.LOOKUPKEY.TICKET_SEVERITY);
        }
        if (group.type == AppConstant.KPI_REPORING.MONITORING) {
          this.filterableValues = AppConstant.ALERT_LEVELS.SYSTEM;
        }
        break;
      case "incidentstatus":
        this.getLookupValues(AppConstant.LOOKUPKEY.TICKET_STATUS);
        break;
      case "category":
        this.getLookupValues(AppConstant.LOOKUPKEY.TICKET_CATEGORY);
        break;
      case "publishyn":
        this.filterableValues = [
          {
            title: "Published",
            value: "Y",
          },
          {
            title: "Not Published",
            value: "N",
          },
        ];
        break;
      case "metric":
        this.filterableValues = AppConstant.METRICS;
        break;
      case "level":
        // this.filterableValues = AppConstant.ALERT_LEVELS.LEVELS;
        this.getLookupValues(AppConstant.LOOKUPKEY.MONITORING_LEVELS);
        break;
      case "cloudprovider":
        this.filterableValues = AppConstant.ACT_CLOUDPROVIDER;
        break;
      case "region":
        this.getAllRegions();
        break;
      case "instancename":
        this.getAllInstances(group.value);
        break;
      case "instancerefid":
        this.getAllInstances(group.value);
        break;
      case "instancetyperefid":
        this.getAllAWSinstancetype();
        break;
      case "cloudstatus":
        this.filterableValues = AppConstant.AWS_CLOUDSTATUS;
        break;
      case "crn":
        this.getResourceType();
        break;
      case "attribute":
        this.attributemenu = true;
        this.attributeList = [...this.attributeList];
        this.getAttributes(this.crn);
        break;
        case "name":
          this.getAllSSL();
          break;
        case "urls":
          this.getSSLUrls();
          break;
    }
    this.filterMenuVisible = true;
    if (
      group.value != "attribute" &&
      group.selectedvalues &&
      group.selectedvalues.length > 0
    ) {
      this.filters[group.value] = {};
      group.selectedvalues.forEach((o) => {
        this.filters[group.value][o["value"]] = true;
      });
    } else {
      this.filters[group.value] = {};
      // if (group.value == 'attribute') {
      //   let i = _.findIndex(this.groups, { value: 'attribute' });
      //   this.filters[group.value] = this.groups[i].selectedvalues.length > 0 ? this.groups[i].selectedvalues : [];
      // } else {
      //   this.filters[group.value] = {};
      // }
    }
  }

  addRow() {
    this.attributeList.push({
      type: null,
      formula: null,
      value: "",
    });
  }
  deleteRow(i) {
    this.attributeList.splice(i, 1);
    this.attributeList = [...this.attributeList];
  }
  onAttributeSelect(event, i, flag?) {
    this.dataloading = true;
    let selectedattributes = _.map(this.attributeList, function (e) { return e.type; });
    var valueArr = selectedattributes.map(function (item) { return item.fieldname });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    if (isDuplicate == false) {
      if (flag) {
        this.attributeList[i].value = "";
        this.attributeList[i].formula = null;
      }
      if (event.fieldtype == "AUTOGEN") {
        this.attributeList[i].maxseqno = Number(event.curseq) - 1;
      }
      this.attributeList[i].operations = _.filter(this.operations, function (e) {
        return _.includes(e.datatypes, event.fieldtype);
      });
      this.assetRecordService
        .getAllDetail({
          crn: event.crn,
          status: AppConstant.STATUS.ACTIVE,
          tenantid: this.tenantid,
          fieldkey: event.fieldkey,
        })
        .subscribe((result) => {
          let response = {} as any;
          response = JSON.parse(result._body);
          if (response.status) {
            this.attributeList[i].possibleValues = response.data.map((el) => {
              return {
                label: el.fieldvalue,
                value: el.fieldvalue,
              };
            });
            this.attributeList[i].possibleValues = _.unionBy(this.attributeList[i].possibleValues, 'value');
          }
          this.dataloading = false;
        });
    } else {
      this.message.error(`${event.fieldname} already selected`);
      this.attributeList[i].type = '';
      this.attributeList = [...this.attributeList];
      this.dataloading = false;
    }


  }
  getAttributes(crn) {
    this.dataloading = true;
    let condition = {
      tenantid: this.tenantid,
      crn: crn,
    };
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
    }
    this.assetRecordService.all(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response) {
        this.attributes = response.data.map((el) => {
          return {
            title: el.fieldname,
            value: el,
          };
        });

        // Edit form
        // let attribute: any = _.find(this.groups, { value: "attribute" });
        // if (attribute != undefined && attribute.selectedvalues.length > 0) {
        //   this.attributeList = [];
        //   attribute.selectedvalues.map((o) => {
        //     let type = _.find(this.attributes, { title: o.fieldname });
        //     let obj = {
        //       type: type["value"],
        //       formula: o.operation,
        //       operations: _.filter(this.operations, function (e) {
        //         return _.includes(e.datatypes, o.fieldtype);
        //       }),
        //       value: o.value,
        //     };
        //     this.attributeList.push(obj);
        //   });
        // }

        if (this.reportdetails['selectedattributes']) {
          this.attributeList = [];
          this.reportdetails['selectedattributes'].map((o, i) => {
            let type = _.find(this.attributes, { title: o.fieldname });
            let obj = {
              type: type["value"],
              formula: o.operation,
              operations: _.filter(this.operations, function (e) {
                return _.includes(e.datatypes, o.fieldtype);
              }),
              value: o.value,
            };
            this.attributeList.push(obj);
            this.onAttributeSelect(o, i);
          });
        } else {
          this.attributeList = [{
            type: null,
            formula: null,
            value: "",
          }];
        }
      } else {
        this.attributes = [];
      }
      this.dataloading = false;
    });
  }
  getResourceType() {
    this.dataloading = true;
    let condition = {
      tenantid: this.tenantid,
    };
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
    }
    this.assetRecordService.getResourceTypes(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response) {
        response = _.orderBy(response, ["resource"], ["asc"]);
        this.filterableValues = response.map((el) => {
          return {
            title: el.resource,
            value: el.crn,
          };
        });
      } else {
        this.filterableValues = [];
      }
      this.dataloading = false;
    });
  }
  getAllCustomers() {
    this.dataloading = true;
    let condition = {
      tenantid: this.tenantid,
      status: AppConstant.STATUS.ACTIVE,
    };

    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
      condition["headers"] = [{ field: "customername" }];
    }
    this.tenantsService.allcustomers(condition).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          return {
            title: el.customername,
            value: el.customerid,
          };
        });
      } else {
        this.filterableValues = [];
      }
      this.dataloading = false;
    });
  }

  applyAttributeFilter() {
    let obj = {
      attribute: this.attributeList.map((o) => {
        return {
          fieldkey: o.type.fieldkey,
          fieldname: o.type.fieldname,
          fieldtype: o.type.fieldtype,
          value: o.value,
          operation: o.formula,
        };
      }),
    };
    this.applyCMDBFilter.next(obj);
  }

  applyfilterby() {
    let values = [];
    for (const key in this.filters[this.currentFilter.value]) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.filters[this.currentFilter.value],
          key
        )
      ) {
        const value = this.filters[this.currentFilter.value][key];
        const r = this.filterableValues.find((o) => o.value == key);
        if (value) {
          values.push({
            title: r.title,
            value: r.value,
          });
        }

      }
    }
    let filter = {};
    filter[this.currentFilter.value] = values;
    if (this.currentFilter.type == AppConstant.KPI_REPORING.TICKETS) {
      this.selectedFilters[AppConstant.KPI_REPORING.TICKETS] = filter;
      let existIndex = _.findIndex(this.groups, {
        value: this.currentFilter.value,
      });
      if (existIndex != -1) this.groups[existIndex].selectedvalues = values;
      this.submitFilters.next({ type: this.reportdetails['reporttype'], value: this.selectedFilters[AppConstant.KPI_REPORING.TICKETS] });
    }
    if (this.currentFilter.type == AppConstant.KPI_REPORING.ASSET) {
      this.selectedFilters[AppConstant.KPI_REPORING.ASSET] = filter;
      let existIndex = _.findIndex(this.groups, {
        value: this.currentFilter.value,
      });
      if (existIndex != -1) this.groups[existIndex].selectedvalues = values;
      this.submitFilters.next({ type: this.reportdetails['reporttype'], value: this.selectedFilters[AppConstant.KPI_REPORING.ASSET] });

    }
    if (this.currentFilter.type == AppConstant.KPI_REPORING.MONITORING) {
      this.selectedFilters[AppConstant.KPI_REPORING.MONITORING] = filter;
      let existIndex = _.findIndex(this.groups, {
        value: this.currentFilter.value,
      });
      if (existIndex != -1) this.groups[existIndex].selectedvalues = values;
      this.submitFilters.next({ type: this.reportdetails['reporttype'], value: this.selectedFilters[AppConstant.KPI_REPORING.MONITORING] });

    }
    if (this.currentFilter.type == AppConstant.KPI_REPORING.SSL) {
      this.selectedFilters[AppConstant.KPI_REPORING.SSL] = filter;
      let existIndex = _.findIndex(this.groups, {
        value: this.currentFilter.value,
      });
      if (existIndex != -1) this.groups[existIndex].selectedvalues = values;
      this.submitFilters.next({ type: this.reportdetails['reporttype'], value: this.selectedFilters[AppConstant.KPI_REPORING.SSL] });

    }
  }
  getAllSeverity() {
    this.dataloading = true;
    this.commonService
      .allLookupValues({
        status: AppConstant.STATUS.ACTIVE,
        lookupkey: AppConstant.LOOKUPKEY.TICKET_SEVERITY,
      })
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.filterableValues = response.data.map((el) => {
            return {
              title: el.keyname,
              value: el.keyvalue,
            };
          });
        } else {
          this.filterableValues = [];
        }
        this.dataloading = false;
      });
  }
  getAllTicketCategory() {
    this.dataloading = true;
    this.commonService
      .allLookupValues({
        status: AppConstant.STATUS.ACTIVE,
        lookupkey: AppConstant.LOOKUPKEY.TICKET_CATEGORY,
      })
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.filterableValues = response.data.map((el) => {
            return {
              title: el.keyname,
              value: el.keyvalue,
            };
          });
        } else {
          this.filterableValues = [];
        }
        this.dataloading = false;
      });
  }
  getTagValues(group) {
    let tagids = [];
    this.dataloading = true;
    let cndtn = {
      status: AppConstant.STATUS.ACTIVE,
      tenantid: this.tenantid,
      tagids: tagids,
    };
    if (this.filterSearchModel != "") {
      cndtn["searchText"] = this.filterSearchModel;
      cndtn["headers"] = [{ field: "tagvalue" }];
    }
    if (group && group.selectedvalues && group.selectedvalues.length > 0) {
      tagids = _.map(group.selectedvalues, function (itm) {
        return itm.value;
      });
      if (tagids.length > 0) {
        cndtn["tagids"] = tagids;
      }
    }
    let query = "?distinct=tagvalue";
    this.tagValueService.all(cndtn, query).subscribe(
      (result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.filterableValues = response.data.map((el) => {
            return {
              title: el.tagvalue == null ? "" : el.tagvalue,
              value: el.tagvalue,
            };
          });
        } else {
          this.filterableValues = [];
        }
        this.dataloading = false;
      },
      (err) => {
        this.dataloading = false;
      }
    );
  }
  getAllTags() {
    this.dataloading = true;
    let reqObj = {
      tenantid: this.tenantid,
      status: AppConstant.STATUS.ACTIVE,
    } as any;
    if (this.filterSearchModel != "") {
      reqObj["searchText"] = this.filterSearchModel;
      reqObj["headers"] = [{ field: "tagname" }];
    }

    this.tagService.all(reqObj).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          return {
            title: el.tagname,
            value: el.tagid,
          };
        });
        this.dataloading = false;
      } else {
        this.filterableValues = [];
        this.dataloading = false;
      }

    });
  }

  getLookupValues(key) {
    this.dataloading = true;
    let condition = {
      status: AppConstant.STATUS.ACTIVE,
      lookupkey: key,
    };
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
      condition["headers"] = [{ field: "keyname" }];
    }
    this.commonService.allLookupValues(condition).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          return {
            title: el.keyname,
            value:
              key == AppConstant.LOOKUPKEY.MONITORING_LEVELS
                ? Number(el.keyvalue)
                : el.keyvalue,
          };
        });
        this.dataloading = false;
      } else {
        this.filterableValues = [];
        this.dataloading = false;
      }

    });
  }
  getAllRegions() {
    this.dataloading = true;
    let condition = {
      status: AppConstant.STATUS.ACTIVE,
      tenantid: this.tenantid,
    };
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
      condition["headers"] = [{ field: "region" }];
    }
    this.tenantsService.allRegions(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          return {
            title: el.region,
            value: el.region,
          };
        });
        this.dataloading = false;
      } else {
        this.filterableValues = [];
        this.dataloading = false;
      }

    });
  }

  getAllInstances(group) {
    this.dataloading = true;
    let condition = {
      status: AppConstant.STATUS.ACTIVE,
      tenantid: this.tenantid,
      customerid: this.customerid
    };
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
      condition["headers"] =
        this.currentFilter.value == "instancerefid"
          ? [{ field: "instancerefid" }]
          : [{ field: "instancename" }];
    }
    this.commonService.allInstances(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          return {
            title: group == "instancename" ? el.instancename : el.instancerefid,
            value: el.instancename,
          };
        });
        this.dataloading = false;
      } else {
        this.filterableValues = [];
        this.dataloading = false;
      }
    });
  }
  getAllAWSinstancetype() {
    this.dataloading = true;
    let condition = {
      status: AppConstant.STATUS.ACTIVE,
    };
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
      condition["headers"] = [{ field: "instancetypename" }];
    }
    this.awsService.allawsinstancetype(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        this.filterableValues = response.data.map((el) => {
          return {
            title: el.instancetypename,
            value: el.instancetypename,
          };
        });
        this.dataloading = false;
      } else {
        this.filterableValues = [];
        this.dataloading = false;
      }
    });
  }

  getAllSSL(){
    this.dataloading = true;
    let condition ={
      tenantid: this.tenantid,
      status: AppConstant.STATUS.ACTIVE,
    }as any;
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
        condition["headers"] = [{ field: "name" }]; 
        this.filterSearchModel = '';        
    }
    this.kpiReportingService.getAllssl(condition).subscribe((result) => {
      let response = JSON.parse(result._body);
      if(response.status){
        this.filterableValues = response.data.rows.map((data) => {
          return {
            title: data.name,
            value: data.id,            
          };
        });
      } else {
        this.filterableValues = [];
      }
      this.dataloading = false;
    });
  }

  getSSLUrls(){
    this.filterableValues = [];
    this.dataloading = true;
    let condition ={
      tenantid: this.tenantid,
      status: AppConstant.STATUS.ACTIVE,
    }as any;
    if (this.filterSearchModel != "") {
      condition["searchText"] = this.filterSearchModel;
        condition["headers"] = [{ field: "url" }]; 
        this.filterSearchModel = '';        
    }
    this.kpiReportingService.getAllssl(condition, `?detail=${true}`).subscribe((result) => {
      let response = JSON.parse(result._body);
      if(response && response.data){
        response.data.map((data : any) => {
          this.filterableValues.push({
            title: data.url,
            value: data.url, 
        });
        });
    }
    else {
      this.filterableValues = [];
    }
    this.dataloading = false;
    });
  }

  removeAppliedFilter(data) {
    delete this.filters[data.value];
    let existgrp = _.findIndex(this.groups, { value: data.value });
    if (existgrp != -1) {
      this.groups[existgrp]["selectedvalues"] = [];
    }
    this.groups[existgrp].selectedvalues = [];
    let d = {};
    this.groups.forEach((el) => {
      if (el.selectedvalues.length > 0) {
        d[el.value] = el.selectedvalues;
      }
    });
    if (data.type == AppConstant.KPI_REPORING.TICKETS) {
      this.applyTicketFilter.next(d);
    }
    if (data.type == AppConstant.KPI_REPORING.MONITORING) {
      this.applyMonitoringFilter.next(d);
    }
    if (data.type == AppConstant.KPI_REPORING.ASSET) {
      this.applyAssetFilter.next(d);
    }
    if (data.type == AppConstant.KPI_REPORING.SSL) {
      this.applySSLFilter.next({ data: d, remove: true });
    }
    // if (data.type == AppConstant.KPI_REPORING.CMDB) {
    //   this.applyCMDBFilter.next({ data: d, remove: true });
    // }
  }

  getFieldValues() {
    this.getFilterValue(this.currentFilter);
  }
}
