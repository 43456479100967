import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstant } from "../app.constant";
import { HttpHandlerService } from "../services/http-handler.service";

@Injectable({
  providedIn: "root",
})
export class AssetRecordService {
  endpoint: string;
  serviceURL: any;
  constructor(private httpHandler: HttpHandlerService) {
    this.endpoint = AppConstant.API_END_POINT;
    this.serviceURL = AppConstant.API_CONFIG.API_URL.BASE.ASSETRECORDS;
  }
  all(data): Observable<any> {
    return this.httpHandler.POST(this.endpoint + this.serviceURL.FINDALL, data);
  }

  getAllDetail(data, query?): Observable<any> {
    let url = this.endpoint + this.serviceURL.FINDALLDTL;
    if (query) {
      url = url + "?" + query;
    }
    return this.httpHandler.POST(url, data);
  }
  getResourceTypes(data) {
    return this.httpHandler.POST(this.endpoint + this.serviceURL.RESOURCETYPE, data);
  }
  txnrefList(data:any){
    return this.httpHandler.POST(
      this.endpoint + this.serviceURL.TXNREFLIST,
      data
    );
  }
  getResource(tenantid: number, crn: string,dtl_operationtype?:string) {
    let req:any= {
      tenantid : tenantid,
    }
    if(dtl_operationtype){
      req.dtl_operationtype = dtl_operationtype;
    }
    return this.httpHandler.POST(
      this.endpoint + this.serviceURL.RESOURCE.replace("{type}", crn),
      {
        tenantid,
      }
    );
  }
  getResourceFieldValues(body: Record<string, any>) {
    return this.httpHandler.POST(
      this.endpoint + this.serviceURL.RESOURCEFIELDVALUES,
      body
    );
  }
  getResourceAssets(body: Record<string, any>) {
    return this.httpHandler.POST(
      this.endpoint + this.serviceURL.RESOURCEDETAILS,
      body
    );
  }
  getAppdynamicToken(query?:string){
    let url = this.endpoint + this.serviceURL.GETAPPDYNAMICSTOKEN
    url = query ? url + query : url;
    return this.httpHandler.GET(url);
  }
  getAppDynamicChartData(body: Record<string, any>) {
    return this.httpHandler.POST(
      this.endpoint + this.serviceURL.GETAPPDYNAMICDATA,
      body
    );
  }
}
