import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { TenantsService } from "../../services/tenants.service";
import { Buffer } from "buffer";
import { DomSanitizer } from "@angular/platform-browser";
import { LocalStorageService } from "src/app/services/shared/local-storage.service";
import { AppConstant } from "src/app/app.constant";
import { CommonService } from "src/app/services/common.service";
@Component({
  selector: "app-home",
  styleUrls: ["./home.component.css"],
  templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit {
  dataSet = [];
  overview = true;
  tab1 = true;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  customerid;
  userstoragedata = {} as any;
  customerObj = {} as any;
  components = [];
  ishomeSpinning = true;
  kpireportObj = [];
  maintenanceList = [];
  hasAuthentication = false;
  dailyreportWidgetList:any[]=[];
  isDailyReportavail:boolean=false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tenantsService: TenantsService,
    private domsanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
  ) {
    this.route.params.subscribe((params) => {
      if (params["customerid"] !== undefined) {
        this.customerid = Number(params.customerid);
        this.getCustomerInfo();
      }
    });
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.ishomeSpinning = true;
  }
  logout() {
    this.localStorageService.clearAllItem();
    this.router.navigate(["login/" + this.customerid]);
  }
  getCustomerInfo() {
    this.tenantsService
      .customerbyId(
        this.customerid,
        `download=${true}&` +
          "include=" +
          JSON.stringify(["maintenancewindowmap"])
      )
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.customerObj = response.data.data;
          let filterValue = this.customerObj.dashboardconfig;
          if (filterValue) {
            let auth = filterValue.find((el) => {
              return el.key == "AUTHREQUIRED";
            });
            this.hasAuthentication = auth ? auth.value : false;
            let keyvalue = filterValue.find((el) => {
              return el.key == "COMPONENTS";
            });
            this.components = [];
            this.kpireportObj = [];
            if (keyvalue && keyvalue.value) {
              keyvalue.value.map((e) => {
                let obj = JSON.parse(e);
                if (obj.key) {
                  this.components.push(obj.key);
                }
                if (obj.id) {
                  this.kpireportObj.push(obj);
                }
              });
            }
            if (this.components) {
              if (
                !this.components.includes("summary") &&
                this.components.includes("historical_uptime")
              ) {
                this.tab3 = true;
                this.tab1 = false;
                this.tab2 = false;
              }
              if (
                !this.components.includes("summary") &&
                this.components.includes("reporting")
              ) {
                this.tab2 = true;
                this.tab1 = false;
                this.tab3 = false;
              }
              if (
                this.components.includes("businesskpireport")
              ) {
                this.tab4 = true;
                this.tab2 = false;
                this.tab1 = false;
                this.tab3 = false;
              }
            }
          }
          if (response.data.content != null) {
            var buffer = Buffer.from(response.data.content.data);
            const TYPED_ARRAY = new Uint8Array(buffer);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, "");
            let base64String = btoa(STRING_CHAR);
            this.customerObj.logo = this.domsanitizer.bypassSecurityTrustUrl(
              "data:image/jpg;base64, " + base64String
            );
          }

          this.maintenanceList = _.filter(
            response.data.maintenancewindowmap,
            (itm) => {
              if (itm.maintwindow.status == "Active") {
                itm.startdate = moment(itm.maintwindow.startdate).format(
                  "YYYY-MM-DD"
                );
                itm.enddate = moment(itm.maintwindow.enddate).format(
                  "YYYY-MM-DD"
                );
                return itm;
              }
            }
          );
        }
        this.ishomeSpinning = false;
        this.getDailyReportWidgetconfigs();
      });
  }
  checkDailyReportExist(){
    this.isDailyReportavail=false;
    if(this.dailyreportWidgetList.length > 0){
      let dailyReportavail = _.find(this.components,(d:any)=>{
        try {
          let exist=false;
          if(d == "dailyreport"){
            exist = true;
          }
          _.each(this.dailyreportWidgetList,(w)=>{
            if(w.keyname == d){
              exist = true;
            }
          })
          return exist;
        } catch (error) {
          return false;
        }
      });
      if(dailyReportavail){
        this.isDailyReportavail=true;
      }
    }
  }
  getDailyReportWidgetconfigs(){
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.DREPORT_WGT,
      status: AppConstant.STATUS.ACTIVE,
    };
    this.dailyreportWidgetList = [];
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.dailyreportWidgetList = response.data;
      }
      this.checkDailyReportExist()
    });
  }
}
