import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  HostListener,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { KPIReportingService } from "../../services/kpi-reporting.service";
import * as _ from "lodash";
import * as moment from "moment";
import { Buffer } from "buffer";
import downloadService from "../../services/shared/download.service";
import { CommonService } from "../../services/common.service";
import { AssetRecordService } from "../../services/assetrecords.service";
import { AppConstant } from "src/app/app.constant";
import { DashboardConfigService } from "../../services/dashboard-config.service";
import { DomSanitizer } from "@angular/platform-browser";
import { LocalStorageService } from "src/app/services/shared/local-storage.service";
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Subscription } from "rxjs";
const DEFAULT_DURATION = 300;
@Component({
  selector: "daily-report",
  templateUrl: "./dailyreport.component.html",
  styleUrls: ["./dailyreport.component.css"],
  animations: [
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate(DEFAULT_DURATION + "ms ease-in")),
      transition("true => false", animate(DEFAULT_DURATION + "ms ease-out")),
    ]),
  ],
})
export class DailyReportComponent implements OnInit {
  @Input() dailyreportObj: any = [];
  @Input() customerObj: any = {};
  isSpinning: boolean = false;
  customerid: any;
  dateRange: any;
  dataloading: boolean = false;
  customerProducts: any = [];
  currentActiveTab = -1;
  dailyreportWidgetList: any[] = [];
  dailyreportFolderList: any[] = [];
  dashboardConfig = [];
  groupList: any[] = [];
  productmaprefList: any[] = [];
  components: any;
  widgetPageLimit = 8;
  iscomparedailyreport: boolean = false;
  appdynamicAccessToken = "";
  appDynamicConfgs;
  appdynamicBaseUrl = "";
  isValidlogin: boolean = false;
  isSidebysideview: boolean = false;
  constructor(
    private kpiReportingService: KPIReportingService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private assetRecordService: AssetRecordService,
    private dashboardConfigService: DashboardConfigService,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService
  ) {
    this.route.params.subscribe((params) => {
      if (params["customerid"] !== undefined) {
        this.customerid = Number(params.customerid);
      }
    });
  }

  ngOnInit() {
    this.verifyCustomer();
    this.getDashboardConfig();
    this.getDailyReportWidgetconfigs();
    this.getDailyReportFolderconfigs();
    this.checkAppdynamicToken();
    // this.getProdutMappingList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dailyreportObj && changes.dailyreportObj.currentValue) {
      this.dailyreportObj = changes.dailyreportObj.currentValue;
    }
    if (changes.customerObj && changes.customerObj.currentValue) {
      this.customerObj = changes.customerObj.currentValue;
      this.customerProducts = this.customerObj.products;
      if (this.customerProducts.length > 0) {
        this.currentActiveTab = 0;
        this.customerProducts = _.map(this.customerProducts, (p: any) => {
          p.isLoaded = false;
          // p.selectedDate = new Date();
          p.selectedDates = [];
          let lastweek = new Date().getTime() - 60 * 60 * 24 * 7 * 1000;
          p.selectedDates.push(new Date(lastweek));
          p.selectedDates.push(new Date());
          p.selectedDates2 = [];
          let lastweek2 = new Date().getTime() - 2 * 60 * 60 * 24 * 7 * 1000;
          p.selectedDates2.push(new Date(lastweek2));
          p.selectedDates2.push(new Date(lastweek));
          p.compareEnabled = false;
          p.dashboardconfigList = [];
          p.readyToLoadconfigList = [];
          p.loadedIndex = -1;
          p.WidgetCount = 0;
          // p.selectedGroup=null;
          return p;
        });
      }
    }
  }
  onDateRangeChange(event: any) {}
  setActiveTabIdx(idx) {
    this.currentActiveTab = idx;
    this.customerProducts[idx].isLoaded = true;
  }
  getDailyReportWidgetconfigs() {
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.DREPORT_WGT,
      status: AppConstant.STATUS.ACTIVE,
    };
    this.dailyreportWidgetList = [];
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.dailyreportWidgetList = _.orderBy(
          response.data,
          ["lookupid"],
          ["asc"]
        );
        this.checkDailyReportExist();
      }
    });
  }
  checkDailyReportExist() {
    let filterValue = this.customerObj.dashboardconfig;
    let showAll: boolean = false;
    this.iscomparedailyreport = false;
    if (filterValue) {
      let keyvalue = filterValue.find((el) => {
        return el.key == "COMPONENTS";
      });
      this.components = [];
      if (keyvalue && keyvalue.value) {
        keyvalue.value.map((e) => {
          let obj = JSON.parse(e);
          if (obj.key) {
            this.components.push(obj.key);
            if (obj.key == "dailyreport") {
              showAll = true;
            }
          }
        });
      }
      let compare_keyvalue = filterValue.find((el) => {
        return el.key == "COMPAREDAILYREPORT";
      });
      if (compare_keyvalue) {
        this.iscomparedailyreport =
          compare_keyvalue.value == true ? true : false;
      }
    }
    if (!showAll) {
      this.dailyreportWidgetList = _.filter(
        this.dailyreportWidgetList,
        (w: any) => {
          let exist = false;
          _.each(this.components, (c) => {
            if (c == w.keyname) {
              exist = true;
            }
          });
          return exist;
        }
      );
    }
    // if (this.dailyreportWidgetList.length > 0) {
    //   let dailyReportavail = _.find(this.components, (d: any) => {
    //     try {
    //       let exist = false;
    //       if (d == "dailyreport") {
    //         exist = true;
    //       }
    //       _.each(this.dailyreportWidgetList, (w) => {
    //         if (w.keyname == d) {
    //           exist = true;
    //         }
    //       });
    //       return exist;
    //     } catch (error) {
    //       return false;
    //     }
    //   });
    //   if (dailyReportavail) {
    //     this.isDailyReportavail = true;
    //   }
    // }
  }
  getDailyReportFolderconfigs() {
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.DREPORT_WGT_FOLDER,
      status: AppConstant.STATUS.ACTIVE,
    };
    this.dailyreportFolderList = [];
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.dailyreportFolderList = response.data;
      }
    });
  }
  checkAppdynamicToken() {
    // appdynamicBaseUrl
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.TN_INT,
      status: AppConstant.STATUS.ACTIVE,
      keyname: "appdynamics",
      tenantid: AppConstant.ON_PREM_TENANTID,
    };
    this.dailyreportFolderList = [];
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        // this.appdynamicBaseUrl=
        if (response.data) {
          let appdConfig = response.data[0];
          if (appdConfig) {
            let keyvalue = appdConfig.keyvalue;
            try {
              let config = JSON.parse(keyvalue);
              if (config.url) {
                this.appdynamicBaseUrl = config.url;
                this.appDynamicConfgs = config;
                this.getAppdynamicToken();
              }
            } catch (error) {}
          }
        }
      }
    });
  }
  getAppdynamicToken() {
    this.getAppdToken();
    // let token:any = localStorage.getItem(AppConstant.LOCALSTORAGE.APPDYNAMIC_TOKEN);
    // if(token && token !="" ){
    //   const headers = {
    //     Authorization: `Bearer ${token}`,
    //   };
    //   let req = {
    //     method: "get",
    //     url: this.appdynamicBaseUrl+"controller/rest/applications?output=json",
    //     headers: headers,
    //     data: {},
    //   };
    //   this.assetRecordService
    //     .getAppDynamicChartData(req)
    //     .subscribe((result: any) => {
    //       this.dataloading=false;
    //       let response = JSON.parse(result._body);
    //       if (response.status) {
    //         this.appdynamicAccessToken = token;
    //       }
    //     },(error)=>{
    //       this.getAppdToken();
    //     });
    // }
    // else {
    //  this.getAppdToken();
    // }
  }
  getAppdToken() {
    this.assetRecordService.getAppdynamicToken().subscribe((result: any) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        if (response.data) {
          this.appdynamicAccessToken = response.data.access_token;
          localStorage.setItem(
            AppConstant.LOCALSTORAGE.APPDYNAMIC_TOKEN,
            this.appdynamicAccessToken
          );
        }
      }
    });
  }
  getDashboardConfig() {
    this.groupList = [];
    this.isSpinning = true;
    let reqObj = {
      customerid: this.customerid,
      dailyreportyn: "Y",
      status: AppConstant.STATUS.ACTIVE,
    } as any;
    let query = "?include=detail";
    this.commonService.allDashboardConfig(reqObj, query).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.dashboardConfig = response.data;
        this.groupList = [];
        this.dashboardConfig = _.sortBy(this.dashboardConfig, ["displayorder"]);
        _.map(this.dashboardConfig, (item, idx) => {
          let obj = {
            sectionname: item.sectionname,
            confighdrid: item.confighdrid,
            customerid: this.customerObj.customerid,
            tagid: item.tagid,
            tagvalue: item.tagvalue,
            reportyn: item.reportyn == "Y" ? true : false,
            downtimeyn: item.downtimeyn == "Y" ? true : false,
            dailyreportyn: item.dailyreportyn == "Y" ? true : false,
            uptime: item.uptime,
            status: item.status,
            lastupdateddt: new Date(),
            dashboardconfigdetails: [],
          };
          item.dashboardconfigdetails = _.sortBy(item.dashboardconfigdetails, [
            "displayorder",
          ]);
          obj.dashboardconfigdetails = _.map(
            item.dashboardconfigdetails,
            (itm, i) => {
              itm.label = itm.instances.instancename;
              itm.referenceid = itm.instances.instanceid;
              itm.instancerefid = itm.instances.instancerefid;
              itm.confighdrid = obj.confighdrid;
              itm.displayname = itm.displayname;
              itm.uptime = itm.uptime;
              itm.reportyn = itm.reportyn == "Y" ? true : false;
              itm.downtimeyn = itm.downtimeyn == "Y" ? true : false;
              itm.dailyreportyn = itm.dailyreportyn == "Y" ? true : false;
              itm.lastupdateddt = new Date();
              return itm;
            }
          );
          obj.dashboardconfigdetails = _.filter(
            obj.dashboardconfigdetails,
            (c) => {
              return c.dailyreportyn == true;
            }
          );
          if (obj.dailyreportyn) {
            this.groupList.push(obj);
          }
          this.isSpinning = false;
        });
      } else {
        this.dashboardConfig = [];
        this.isSpinning = false;
      }
      this.getProdutMappingList();
    });
  }
  verifyCustomer() {
    let token: any = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.TOKEN
    );
    if (token) {
      let option = {
        "x-auth-header": token,
      };
      this.commonService.verifyCustomer(option).subscribe(
        (result) => {
          let response = JSON.parse(result._body);
          if (response.status) {
            this.isValidlogin = true;
          } else {
            this.dashboardConfig = [];
            this.isSpinning = false;
          }
        },
        (err) => {
          this.isValidlogin = false;
          this.dashboardConfig = [];
          this.isSpinning = false;
        }
      );
    }
    let option = {
      Authorization: `Bearer glsa_7wzoOtv6WCEEJkBZmd8AE0BS4nYqgx36_0950951f`,
    };
    let url = `https://grafana.opsmaster.ai/api/dashboards/home`;
    this.commonService.verifyGrafanaAuth(url, option).subscribe(
      (result) => {},
      (err) => {
        console.error(err);
      }
    );
  }
  getWidgetFolderConfig(instanceconfig, widget) {
    let folderconfig;
    try {
      let widgetConfig: any = JSON.parse(widget.keyvalue);
      let widgetFolder = widgetConfig.folder;
      let platform: string = instanceconfig.instances.platform;
      let port = 9088;
      if (widgetFolder != "wcr_em_plugin") {
        switch (platform.toLowerCase()) {
          case "windows":
            widgetFolder = "admin-cm-windows";
            port = 9182;
            break;
          case "linux":
            widgetFolder = "admin-cm-linux";
            port = 9100;
            break;
          default:
            port = 9088;
            break;
        }
      }

      folderconfig = _.find(this.dailyreportFolderList, (folder: any) => {
        return folder.keyname == widgetFolder;
      });
      if (!folderconfig) {
        folderconfig = this.dailyreportFolderList[0];
      }
      let validChart = true;
      if (
        widgetConfig.folder != widgetFolder &&
        widgetConfig.source == "grafana"
      ) {
        validChart = false;
      }
      return { folderconfig, validChart, port };
    } catch (error) {
      folderconfig = this.dailyreportFolderList[0];
      let validChart = true;
      return { folderconfig, validChart };
    }
  }
  onDateChange($event, data, pidx) {
    setTimeout(() => {
      this.changegroup(data, pidx);
    }, 50);
  }
  onCompareEnabled($event, data, pidx) {
    setTimeout(() => {
      if (data) {
        this.changegroup(data, pidx);
      }
    }, 50);
  }
  changegroup(data, pidx) {
    if (data.dashboardconfigdetails) {
      this.isSpinning = true;
      try {
        let productMatch = this.filterProductMapping(
          _.cloneDeep(data.dashboardconfigdetails),
          this.customerProducts[pidx].productid
        );
        let WidgetCount = 0;
        productMatch = _.map(productMatch, (config: any) => {
          config.expanded = false;
          config.chartWidgets = [];
          let getLastWeeksDate = (selectedDate) => {
            return selectedDate.getTime() - 7 * 24 * 60 * 60 * 1000;
          };
          _.each(this.dailyreportWidgetList, (w) => {
            let { folderconfig, validChart, port } = this.getWidgetFolderConfig(
              config,
              w
            );
            let isEnableVMLvlWidget = true;
            if (config.dailyreportconfig && config.dailyreportconfig != null) {
              try {
                let dailyreportconfig: any[] = JSON.parse(
                  config.dailyreportconfig
                );
                let dconfigExist = _.find(dailyreportconfig, (a) => {
                  return a.key == w.keyname;
                });
                if (!dconfigExist) {
                  isEnableVMLvlWidget = false;
                }
              } catch (error) {
                isEnableVMLvlWidget = true;
              }
            }
            if (w.datatype == "json" && folderconfig && isEnableVMLvlWidget) {
              try {
                let v: any = JSON.parse(w.keyvalue);
                let f: any = JSON.parse(folderconfig.keyvalue);

                let selectedDates: Date[] =
                  this.customerProducts[pidx].selectedDates;
                let fromdate: Date = new Date(_.cloneDeep(selectedDates[0]));
                fromdate.setHours(0);
                fromdate.setMinutes(0);
                fromdate.setSeconds(0);
                let todate: Date = new Date(_.cloneDeep(selectedDates[1]));
                todate.setHours(23);
                todate.setMinutes(59);
                if (todate.toDateString() == new Date().toDateString()) {
                  todate.setHours(new Date().getHours());
                  todate.setMinutes(new Date().getMinutes());
                }
                let instanceip = config.instances.privateipv4;
                let tovalue = todate.valueOf();
                let attribute =  `&var-instance=${instanceip}:${port}`
                if (v.allowInstanceparams) {
                  if (config.instances) {
                    instanceip = config.instances.privateipv4;
                    instanceip = `${instanceip}:${port}`;
                    attribute = `&var-instance=${instanceip}`
                  }
                }
                let formattedUrl =
                  f.url +
                  `&theme=light&panelId=${
                    v.panelId
                  }${attribute}&from=${fromdate.valueOf()}&to=${tovalue}`;
                if (v.source == "grafana") {
                  v.url =
                    this.sanitizer.bypassSecurityTrustResourceUrl(formattedUrl);
                  WidgetCount += 1;
                }

                v.periodSet = 1;
                v.fromTimestamp = fromdate.valueOf();
                v.toTimestamp = tovalue;
                if (validChart) {
                  config.chartWidgets.push(v);
                }

                //period 2
                if (this.customerProducts[pidx].compareEnabled) {
                  let selectedDates2: Date[] =
                    this.customerProducts[pidx].selectedDates2;
                  let fromdate2: Date = new Date(selectedDates2[0]);
                  fromdate2.setHours(0);
                  fromdate2.setMinutes(0);
                  fromdate2.setSeconds(0);
                  let todate2: Date = new Date(selectedDates2[1]);
                  todate2.setHours(23);
                  todate2.setMinutes(59);
                  if (todate2.toDateString() == new Date().toDateString()) {
                    todate2.setHours(new Date().getHours());
                    todate2.setMinutes(new Date().getMinutes());
                  }
                  let todate2Value = todate2.valueOf();
                  let formattedUrl2 =
                    f.url +
                    `&theme=light&panelId=${
                      v.panelId
                    }${attribute}&from=${fromdate2.valueOf()}&to=${todate2Value}`;
                  let v2 = Object.assign({}, v);
                  if (v.source == "grafana") {
                    v2.url =
                      this.sanitizer.bypassSecurityTrustResourceUrl(
                        formattedUrl2
                      );
                  }
                  v2.periodSet = 2;
                  v2.fromTimestamp = fromdate2.valueOf();
                  v2.toTimestamp = todate2Value;
                  if (validChart) {
                    config.chartWidgets.push(v2);
                  }
                }
                console.log(config.chartWidgets);
              } catch (error) {
                console.error(error);
              }
            }
          });
          // config.WidgetCount=config.chartWidgets.length || 0;
          // config.readytoLoadWidget=[];
          // if(config.WidgetCount > this.widgetPageLimit){
          //   let widgetSplice=config.chartWidgets.slice(0,this.widgetPageLimit-1);
          //   config.readytoLoadWidget=widgetSplice;
          //   config.loadedIndex=this.widgetPageLimit-1;
          // }
          // else{
          //   config.readytoLoadWidget = config.chartWidgets;
          //   config.loadedIndex = config.WidgetCount-1;
          // }
          return config;
        });
        // let selectedGroup = data;
        // selectedGroup.dashboardconfigdetails=dashboardconfigdetails

        this.customerProducts[pidx].dashboardconfigList = productMatch;
        this.customerProducts[pidx].WidgetCount = WidgetCount;
        this.customerProducts[pidx].readyToLoadconfigList = productMatch;
        // Pagination
        // if(WidgetCount >= this.widgetPageLimit){
        //   this.customerProducts[pidx].loadedIndex=0;
        //   this.customerProducts[pidx].readyToLoadconfigList = productMatch.slice(0,1);
        // }
        // else{
        //   this.customerProducts[pidx].readyToLoadconfigList = productMatch;
        // }
        this.customerProducts = [...this.customerProducts];
        this.isSpinning = false;
      } catch (error) {
        this.isSpinning = false;
      }
    }
  }
  filterProductMapping(dashboardconfigdetails: any[], productid) {
    let productMatch = [];
    _.each(dashboardconfigdetails, (config: any) => {
      let ins = _.find(this.productmaprefList, (p) => {
        return p.txn == config.instancerefid && p.refkey == productid;
      });
      if (ins) {
        productMatch.push(config);
      }
    });
    return productMatch;
  }

  isValidChart(chartWidhet, config) {
    return true;
  }
  getProdutMappingList() {
    // call api to get the header and details of the assets
    this.isSpinning = true;
    this.assetRecordService
      .txnrefList({
        // refkey : this.customerid,
        module: AppConstant.CMDB_OPERATIONTYPE[8],
      })
      .subscribe((txndata) => {
        let res = JSON.parse(txndata._body);
        if (res.status) {
          this.productmaprefList = res.data || [];
        }
        this.isSpinning = false;
      });
  }
  setproductMatchConfig() {
    _.map(this.customerProducts, (p) => {});
  }
  @HostListener("scroll", ["$event"])
  scrollIframeHandler(event: any, product, pidx) {
    event.cancelBubble = true;
    // if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
    //   let loadIndex=this.customerProducts[pidx].loadedIndex  + 1;
    //   if(this.customerProducts[pidx].dashboardconfigList[loadIndex]){
    //     this.customerProducts[pidx].readyToLoadconfigList.push(this.customerProducts[pidx].dashboardconfigList[loadIndex]);
    //     // _.each(this.customerProducts[pidx].dashboardconfigList[loadIndex],(c)=>{
    //     //   this.customerProducts[pidx].readyToLoadconfigList.push(c);
    //     // });
    //     // this.customerProducts[pidx].dashboardconfigList[loadIndex].chartWidgets=[...this.customerProducts[pidx].dashboardconfigList[loadIndex].chartWidgets];
    //     this.customerProducts[pidx].loadedIndex = loadIndex;
    //     this.customerProducts[pidx].readyToLoadconfigList=[...this.customerProducts[pidx].readyToLoadconfigList];
    //   }
    // }
  }
  notifyLoginChanges(data) {
    this.isValidlogin = true;
    this.verifyCustomer();
  }
  expandInstance(instance) {
    instance.expanded = !instance.expanded;
  }
}
