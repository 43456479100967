import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { AppConstant } from "src/app/app.constant";
import * as moment from "moment";
import { DashboardConfigService } from "../../services/dashboard-config.service";
import { IncidentService } from "../../services/incidents.service";
import { PrometheusService } from "../../services/prometheus.service";
import { NzOptionSelectionChange } from "ng-zorro-antd";
@Component({
  selector: "app-metrics-view",
  templateUrl: "./metricsview.component.html",
  styleUrls: ["./metricsview.component.css"],
})
export class MetricsViewComponent implements OnInit {
  @Input() customerid: Number;
  @Input() maintenanceList = [];
  @Input() customerObj = {} as any;
  @Input() kpireportObj;
  uptime = false;
  tab1 = false;
  tab2 = true;
  tab3 = false;
  userstoragedata = {} as any;
  incidentList = [];
  dashboardConfig = [];
  selectedTag = null;
  calendarSet = [];
  components = [];
  formattedList = [];
  dinstancesAvgs = [];
  isSpinning = false;
  startMonth: Date;
  endMonth: Date;
  constructor(
    private router: Router,
    private incidentService: IncidentService,
    private dashboardConfigService: DashboardConfigService,
    private prometheusService: PrometheusService
  ) {
    this.setCurrentDateRange();
  }

  ngOnInit() {
    this.getCustomerInfo();
    this.getDashboardConfig();
  }
  gotoHome() {
    this.router.navigate(["dashboard/" + this.customerid]);
  }
  dateFormat(date) {
    return moment(date * 1000).format("DD-MM-YYYY");
  }
  setCurrentDateRange(event?) {
    if (event) {
      this.endMonth = moment(this.startMonth)
        .startOf("month")
        .subtract(2, "month")
        .toDate();
      this.getInstanceRefs();
    } else {
      this.startMonth = moment(moment().startOf("month"))
        .subtract(2, "month")
        .toDate();
      this.endMonth = moment().endOf("month").toDate();
    }
  }

  getInstanceRefs() {
    let dashboardConfig = _.find(this.dashboardConfig, {
      value: this.selectedTag,
    });
    let endmonth = moment().format("MM-DDT");
    // let startmonth = moment().subtract(30, "days").format("MM-DDT")
    // Fix me - Date range

    let startdate =
      moment(this.startMonth).startOf("month").format("YYYY-MM-DD") +
      "T" +
      "00:00:00.000Z";
    let enddate =
      moment(this.endMonth).endOf("month").format("YYYY-MM-DD") +
      "T" +
      "00:00:00.000Z";
    let data = {
      customer: this.customerid,
      customerid: this.customerid,
      start: startdate.toString(),
      end: enddate.toString(),
      configids: dashboardConfig.ids,
    };
    if (this.customerid == AppConstant.NESLE_CUSTOMERID) {
      data["customerid"] = this.customerid;
    }
    this.prometheusService.getCalendarData(data).subscribe((d) => {
      let response = JSON.parse(d._body);
      this.dinstancesAvgs = response.data.dinstancesAvgs;
      this.getCurrentData();
    });
  }
  getCustomerInfo() {
    let filterValue = this.customerObj.dashboardconfig;
    if (filterValue) {
      let keyvalue = filterValue.find((el) => {
        return el.key == "COMPONENTS";
      });
      this.components = [];
      if (keyvalue && keyvalue.value) {
        keyvalue.value.map((e) => {
          let obj = JSON.parse(e);
          if (obj.key) {
            this.components.push(obj.key);
          }
        });
      }
      if (
        this.components &&
        !this.components.includes("uptime") &&
        this.components.includes("incidents")
      ) {
        this.tab2 = false;
        this.tab1 = true;
      }
      if (
        this.components &&
        this.components.includes("uptime") &&
        !this.components.includes("incidents")
      ) {
        this.tab2 = true;
        this.tab1 = false;
      }
      if (this.components && this.components.includes("historical_uptime")) {
        this.tab2 = true;
        this.tab1 = false;
      }
    }
    this.maintenanceList = _.filter(
      this.customerObj.maintenancewindowmap,
      (itm) => {
        if (itm.maintwindow.status == "Active") {
          itm.startdate = moment(itm.maintwindow.startdate).format(
            "YYYY-MM-DD"
          );
          itm.enddate = moment(itm.maintwindow.enddate).format("YYYY-MM-DD");
          return itm;
        }
      }
    );
  }
  getDashboardConfig() {
    this.isSpinning = true;
    let reqObj = {
      customerid: this.customerid,
      status: AppConstant.STATUS.ACTIVE,
    } as any;
    let query = "?include=tag";
    this.dashboardConfigService.all(reqObj, query).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        response.data = _.sortBy(response.data, ["displayorder"]);
        _.map(response.data, (itm) => {
          let exist = _.find(this.dashboardConfig, {
            label: itm.sectionname,
          });
          let index = _.indexOf(this.dashboardConfig, exist);
          if (exist != undefined) {
            exist.ids.push(itm.confighdrid);
            this.dashboardConfig[index] = exist;
          } else {
            this.dashboardConfig.push({
              label: itm.sectionname,
              value: itm.confighdrid,
              ids: [itm.confighdrid],
            });
          }
          return itm;
        });
        this.selectedTag = this.dashboardConfig[0].value;
        this.onYrChange();
      } else {
        this.dashboardConfig = [];
        this.isSpinning = false;
        this.onYrChange();
      }
    });
  }

  incidentObj = {} as any;
  getAllIncidents(yr?) {
    let reqObj = {
      customerid: this.customerid,
      status: AppConstant.STATUS.ACTIVE,
      publishyn: "Y",
    } as any;
    let startDate =
      moment(yr).startOf("month").format("YYYY-MM-DD") + "T" + "00:00:00.000Z";
    let endDate =
      moment(yr).endOf("month").format("YYYY-MM-DD") + "T" + "00:00:00.000Z";
    reqObj["startdate"] = startDate;
    reqObj["enddate"] = endDate;
    this.incidentService.all(reqObj).subscribe((result) => {
      let response = JSON.parse(result._body);
      if (response.status) {
        this.isSpinning = false;
        this.incidentObj = _.groupBy(response.data.rows, "incidentdate");
      }
    });
  }
  onYrChange() {
    this.isSpinning = true;
    if (this.tab1) {
      this.getAllIncidents(this.startMonth);
    }
    if (this.tab2) {
      if (this.selectedTag) {
        this.calendarSet = [];
        this.formattedList = [];
        this.dinstancesAvgs = [];
        this.getInstanceRefs();
      }
    }
  }
  formCalendarData() {
    this.calendarSet = [];
    let selectedstrdt = moment(this.startMonth).format("YYYY-MM-01");
    for (var i = 0; i < 3; i++) {
      let startDate = moment(selectedstrdt)
        .add(i, "month")
        .format("YYYY-MM-DD");
      let obj = {
        month: startDate,
        highlights: {},
        days: moment(startDate).daysInMonth(),
        startdt: moment(startDate).startOf("month").format("YYYY-MM-DD"),
        enddt: moment(startDate).endOf("month").format("YYYY-MM-DD"),
        avg: 0,
        sum: 0,
        overallavg: 0,
        overallavgprcnt: 0,
        len: 0,
      } as any;
      this.calendarSet.push(obj);
      this.formDates(obj, i);
      this.isSpinning = false;
    }
  }
  currentData = [];
  getCurrentData() {
    try {
      let exist = this.dinstancesAvgs[0];
      let data = {
        customer: this.customerid,
        customerid: this.customerid,
        tenantid: this.customerObj.tenantid,
        start: moment().format("YYYY-MM-DDT") + "05:30:00.000Z",
        end: moment().format("YYYY-MM-DDT23:59:59.SSS") + "Z",
        instances: _.replace(exist.instances, /,/g, "|"),
      };
      this.prometheusService.getcurrentVMuptime(data).subscribe((d) => {
        let response = JSON.parse(d._body);
        this.currentData = response.data;
        this.formCalendarData();
      });
    } catch (E) {
      console.log(E);
    }
  }
  formDates(obj, i) {
    _.map(_.range(1, obj.days + 1), (itm, idx) => {
      let date = moment(obj.month).add(idx, "days").format("DD-MM-YYYY");
      let dDate = moment(obj.month).add(idx, "days").format("YYYY-MM-DD");
      let CDate = moment().format("YYYY-MM-DD");
      obj.highlights[date] = { color: "#f0f5f5", percentage: 100 };
      let exist = this.dinstancesAvgs[0];
      obj.avg = 0;
      obj.sum = 0;
      let filtereddata: any = _.find(exist.filtereddata, { _id: dDate });
      if (filtereddata != undefined) {
        obj.avg = filtereddata ? filtereddata.avg : 0;
      } else if (
        this.currentData.length > 0 &&
        moment(dDate, "YYYY-MM-DD").isSame(CDate)
      ) {
        obj.sum = _.sumBy(this.currentData, (d: any) => {
          return d.uptimepercent;
        });
        obj.avg = obj.sum / this.currentData.length;
      }
      obj.highlights[date]["percentage"] = obj.avg;

      if (obj.avg >= exist.avg) {
        obj.highlights[date]["color"] = "#A3E798";
      } else if (obj.avg >= 50 && obj.avg < exist.avg) {
        obj.highlights[date]["color"] = "#ffa64d";
      } else if (obj.avg > 0 && obj.avg < 50) {
        obj.highlights[date]["color"] = "#FF0000";
      }
      obj.overallavgprcnt = exist.avg;
      this.calendarSet[i] = obj;
      this.calendarSet = [...this.calendarSet];
    });
  }
}
